<template>
  <div class="coverauth" v-if="show">
    <div class="get_phone_text">请授权您的手机号</div>
    <div class="get_phone" @click="getphone">授权手机号</div>
    <img
      class="dele_logo"
      src="../../cmmon/image/del@2x.png"
      alt=""
      @click="close"
    />
  </div>
</template>
  <script>
import https from "../../cmmon/js/https";

let wx = require("weixin-js-sdk");

export default {
  data() {
    return {
      show: false,
      agentsMsg: {},
    };
  },
  created() {
    this.miniProgram();
  },
  methods: {
    miniProgram() {
      let that = this;
      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          that.getinfo();
        }
      });
    },
    async getinfo() {
      let res = await https.get("user/app/self?do=check");
      if (res.code == 0) {
        this.agentsMsg = res.data;
        let showAuth = sessionStorage.getItem("showAuth");
        if (!this.agentsMsg.phone && showAuth != 2) {
          sessionStorage.setItem("showAuth", 1);
          this.show = true;
          console.log("getinfo2");
        }
      }
    },
    close() {
      sessionStorage.setItem("showAuth", 2);
      this.show = false;
    },
    getphone() {
      console.log("getphone");
      //获取当前环境
      let that = this;
      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          that.toXCX();
        } else {
          that.$toast("请在微信上打开");
        }
      });
    },
    toXCX() {
      wx.miniProgram.navigateTo({
        url: "/pages/authorizedPhone/index?oid=" + this.agentsMsg._id,
      });
    },
  },
};
</script>
  <style lang="less" scoped>
.coverauth {
  position: fixed;
  z-index: 1;
  bottom: calc(50px + constant(safe-area-inset-bottom));
  bottom: calc(50px + env(safe-area-inset-bottom));
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  min-height: 44px;
  line-height: 44px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .get_phone_text {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 20px;
    margin-left: 24px;
  }

  .get_phone {
    background: #ff5f00;
    color: #fff;
    margin-left: 22px !important;
    font-size: 12px;
    width: 80px !important;
    height: 28px;
    line-height: 28px;
    text-align: center;
    margin-right: 56px;
  }

  .dele_logo {
    position: absolute;
    right: 0;
    top: 14px;
    width: 16px;
    height: 16px;
    margin-right: 16px;
  }
}
</style>