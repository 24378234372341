<template>
  <div>
    <!-- 11月秒杀/幸运扭蛋机-->
    <van-dialog
      v-if="type == 2"
      class="dialog-img2"
      v-model="showcoupon"
      :show-confirm-button="false"
      :show-cancel-button="false"
    >
      <div class="showcoupon">
        <img
          class="icon_close"
          src="@/cmmon/image/Tc_gb@2x.png"
          alt=""
          @click="closecoupon"
        />
        <div
          class="popup_con"
          @click.stop="gogoodList"
          :style="{
            background: `url(${
              !isformonth && couponDataDraw.award > 0 ? bg2 : bg1
            }) no-repeat`,
            'padding-top': isformonth ? '132px' : '124px',
          }"
          :class="{ popup_con2: couponDataDraw.award > 0 && !isformonth }"
        >
          <div class="num" v-if="isformonth">
            <span class="value" style="font-size: 13px"
              >社群专属福利，不在社群的不享受此福利</span
            >
          </div>
          <div class="num" v-if="!isformonth">
            <span class="label">您已累计提货</span>
            <span class="value"> ￥{{ couponData.value.toFixed(2) }}</span>
          </div>
          <div class="num" v-else>
            <span class="label">您12月已累计下单</span>
            <span class="value"> ￥{{ couponData.value.toFixed(2) }}</span>
          </div>
          <div class="num" v-if="!isformonth">
            <span class="label">获得</span>
            <span class="value"> {{ couponData.award }} </span>
            <span class="label">次幸运扭蛋机会</span>
          </div>
          <div class="num" v-else>
            <span class="label">获得</span>
            <span class="value"> {{ couponData.award }} </span>
            <span class="label">次秒杀机会</span>
          </div>
          <div class="num" v-if="!isformonth">
            <span class="label">再提货</span>
            <span class="value">
              ￥{{ (couponData.nextValue - couponData.value).toFixed(2) }}
            </span>
          </div>
          <div class="num" v-else>
            <span class="label">再累计下单</span>
            <span class="value">
              ￥{{
                couponData.value
                  ? (couponData.nextValue - couponData.value).toFixed(2)
                  : "500"
              }}
            </span>
          </div>
          <div class="num" v-if="!isformonth">
            <span class="label">预估可再得</span>
            <span class="value"> 1 </span>
            <span class="label">次幸运扭蛋机会</span>
          </div>
          <div class="num" v-else>
            <span class="label">预估可再得</span>
            <span class="value"> 1 </span>
            <span class="label">次秒杀机会</span>
          </div>
          <div
            class="lottery_draw"
            v-if="!isformonth && couponDataDraw.award <= 0"
          >
            <div class="num">
              <span class="title">再提货</span>
              <span class="value">
                ￥{{
                  (couponDataDraw.nextValue - couponDataDraw.value).toFixed(2)
                }}
              </span>
              <span class="title"
                >可获得年会参与资格，参与年会可进行年会抽奖</span
              >
            </div>
          </div>
          <div
            class="lottery_draw"
            v-if="!isformonth && couponDataDraw.award > 0"
          >
            <div class="num">
              <span class="title">您已获得年会参与资格</span><br />
              <span class="title">参与年会可进行年会抽奖</span>
            </div>
            <div class="num">
              <span class="label">当前已获得</span>
              <span class="value">
                {{ couponDataDraw.award }}
              </span>
              <span class="label">次抽奖机会</span>
            </div>
            <div class="num">
              <span class="label">再下单</span>
              <span class="value">
                ￥{{
                  (couponDataDraw.nextValue - couponDataDraw.value).toFixed(2)
                }}
              </span>
            </div>
            <div class="num">
              <span class="label">预估可再得</span>
              <span class="value"> 1 </span>
              <span class="label">次抽奖机会</span>
            </div>
          </div>
        </div>
      </div>
    </van-dialog>
    <!-- 1月秒杀+消费券 -->
    <van-dialog
      v-if="type == 3"
      class="dialog-img2"
      v-model="showcoupon"
      :show-confirm-button="false"
      :show-cancel-button="false"
    >
      <div class="showcoupon">
        <img
          class="icon_close"
          src="@/cmmon/image/Tc_gb@2x.png"
          alt=""
          @click="closecoupon"
        />
        <!-- @click.stop="gogoodList" -->
        <div
          class="popup_con"
          :style="{ background: `url(${bg1}) no-repeat` }"
        >
          <div class="num">
            <span class="label">您2月已累计下单</span>
            <span class="value"> ￥{{ couponData.value.toFixed(2) }}</span>
          </div>
          <div class="num">
            <span class="label">获得消费券</span>
            <span class="value"> ￥{{ couponData.award }} </span>
            <!-- <span class="label">元</span> -->
          </div>
          <div class="num">
            <span class="label">再下</span>
            <span class="value">
              ￥{{ (couponData.nextValue - couponData.value).toFixed(2) }}
            </span>
            <!-- <span class="label">元</span> -->
          </div>
          <div class="num">
            <span class="label">便可获得消费券</span>
            <span class="value"> ￥{{ couponData.nextAward.toFixed(2) }} </span>
            <!-- <span class="label">元</span> -->
          </div>
          <!-- <div class="num">
            <span class="label">获得</span>
            <span class="value"> {{ couponData2.award }} </span>
            <span class="label">次秒杀机会</span>
          </div>
          <div class="num">
            <span class="label">再累计下单</span>
            <span class="value">
              ￥{{ (couponData2.nextValue - couponData2.value).toFixed(2) }}
            </span>
            <span class="label">元</span>
          </div>
          <div class="num">
            <span class="label">预估可再得</span>
            <span class="value"> 1 </span>
            <span class="label">次秒杀机会</span>
          </div> -->
        </div>
      </div>
    </van-dialog>
    <!-- 11月软萌 -->
    <van-popup
      v-if="type == 1"
      v-model="showcoupon"
      closeable
      round
      position="bottom"
      class="rmpopup"
      @closed="closecoupon"
      :style="{
        height: '80%',
        overflow: 'hidden',
      }"
    >
      <div class="guige">
        <p>活动规则</p>
      </div>
      <div class="conBox">
        <span class="ordertotal"> ￥{{ couponData.value.toFixed(2) }}</span>
        <img style="width: 100%" src="@/cmmon/image/11rm_bg@2x.png" alt="" />
      </div>
    </van-popup>
    <!-- <div class="popupIcon"> -->
    <!-- 弹窗按钮 -->
    <img
      class="popupIcon"
      :class="showcoupon ? 'move-box' : 'move-box2'"
      :style="{ bottom: bottom }"
      :src="icon"
      @touchstart.prevent="move"
      alt=""
    />

    <!-- </div> -->
  </div>
</template>
<script>
import http from "../../../cmmon/js/http.js";
import https from "../../../cmmon/js/newhttps.js";
export default {
  data() {
    return {
      showcoupon: false,
      couponData: {
        value: 0,
        award: 0,
        nextValue: 10000,
        nextAward: 300,
      },
      couponDataDraw: {
        value: 0,
        award: 0,
        nextValue: 120000,
        nextAward: 0,
      },
      msItem: {},
      isluckDraw: false,
      isformonth: false,
      couponData2: {
        value: 0,
        award: 0,
        nextValue: 1000,
        nextAward: 1,
      },
    };
  },
  props: {
    icon: {
      type: String,
      default: require("@/cmmon/image/11rm@2x.png"),
    },
    type: {
      type: String,
      default: 1,
    },
    bg1: {
      type: String,
      default: "",
    },
    bg2: {
      type: String,
      default: "",
    },
    formid1: {
      type: String,
      default: "",
    },
    formid1white: {
      type: String,
      default: "",
    },
    formid2: {
      type: String,
      default: "",
    },
    activeid: {
      type: String,
      default: "",
    },
    bottom: {
      type: String,
      default: "220px",
    },
  },
  mounted() {
    this.showcoupon = this.$store.state.showcoupon;
    // this.getcoupondata1();
    // this.getPromotionList(99);
  },
  watch: {
    showcoupon(val) {
      // console.log(val, "val");
      if (
        val &&
        this.type == 2 &&
        this.activeid == "656801bfa834bc236d52886a"
      ) {
        this.getcoupondata1();
        this.getcoupondata2();
        this.isformonth = false;
      } else if (
        val &&
        this.type == 2 &&
        this.activeid == "656937b9661c11b5a28aec32"
      ) {
        this.getformdata3();
        this.getPromotionList(99);
        this.isformonth = true;
      } else if (
        val &&
        this.type == 3
      ) {
       //&&this.activeid == "658bc6e098b2f60278d233f9"
        this.getformdata3();
        // this.getformdata4();
        // this.getPromotionList(99);
        this.isformonth = true;
      }
    },
  },
  methods: {
    //打开弹窗11月秒杀
    // openpopup() {
    //   this.showcoupon = true;
    // },
    closecoupon() {
      this.showcoupon = false;
      localStorage.setItem("couponCount", 1);
    },
    //获取11月秒杀数据
    async getcoupondata1() {
      let res = await https.get(
        "/report/app/wx/sale_result?cid=" + "6567e20fc59dcdd1d46e187b"
      );
      if (res.data) {
        this.couponData = res.data[0];
      }
    },
    async getcoupondata2() {
      let res = await https.get(
        "/report/app/wx/sale_result?cid=" + "6567e285c59dcdd1d46e187c"
      );
      if (res.data) {
        this.couponDataDraw = res.data[0];
      }
    },
    //报表数据
    async getformdata3() {
      let flag = true;
      if (this.formid1white) {
        let res = await https.get(
          "/report/app/wx/sale_result?cid=" + this.formid1white
        );
        if (res.data) {
          this.couponData = res.data[0];
          flag = false;
        }
      }
      if (flag) {
        let res = await https.get(
          "/report/app/wx/sale_result?cid=" + this.formid1
        );
        if (res.data) {
          this.couponData = res.data[0];
        }else{
          this.couponData.nextAward=200
        }
      }
    },
    async getformdata4() {
      let res = await https.get(
        "/report/app/wx/sale_result?cid=" + this.formid2
      );
      if (res.data) {
        this.couponData2 = res.data[0];
      }
    },
    //11秒杀活动
    gogoodList() {
      localStorage.setItem("couponCount", 1);
      if (!this.isformonth) {
        this.toluckDraw();
      } else {
        this.topro(this.msItem);
      }
      // this.topro(this.msItem);
    },
    // 获取活动列表
    getPromotionList(size) {
      let pg = size ? `?skip=0&limit=${size}` : "";
      http
        .get(`/promotion/active${pg}`)
        .then((res) => {
          if (res.ok) {
            if (res.data) {
              let arr = res.data.filter(
                (item) => item.name == "1月累计提货获得秒杀机会"
              );
              this.msItem = arr[0] ? arr[0] : {};
            }
          } else {
            // this.$toast(res.errmsg);
          }
        })
        .catch((err) => {});
    },
    topro(item) {
      if(this.couponData2.award<=0){
        this.$toast("您还没有秒杀机会！");
        return
      }
      if (!item.days) {
        this.$toast("活动未开启");
        return;
      }
      var t;
      if (item.day_type == "weekday") {
        t = new Date().getDay();
      } else if (item.day_type == "month") {
        t = new Date().getDate();
      }
      if (!item.days.includes(t)) {
        if (item.time_remind == "") {
          return;
        }
        this.$toast(item.time_remind);
        return;
      }

      let tt = this.formatDateTime();
      if (item.time_start > tt || tt > item.time_end) {
        if (item.time_remind == "") {
          return;
        }
        this.$toast(item.time_remind);
        return;
      }
      var moneyType = "";
      if (item.moneyType != undefined) {
        moneyType = item.moneyType;
      }
      sessionStorage.setItem("enterremind", item.enterremind);
      this.$router.push({
        path: "/promotion/" + this.activeid,
        query: {
          id: item.id,
          isenterremind: item.isenterremind ? "1" : "0",
          moneytype: moneyType,
        },
      });
      this.showcoupon = false;
      sessionStorage.setItem("is_real_name", item.is_real_name ? "1" : "0");
    },
    toluckDraw() {
      this.isluckDraw = true;
      this.$router.push({
        path: "/twist/luckDraw",
        query: {
          id: "656801bfa834bc236d52886a",
        },
      });
    },
    formatDateTime() {
      const time = new Date();
      let h = time.getHours();
      let m = time.getMinutes();
      time.getSeconds();
      let hh = h * 60 * 60;
      let mm = m * 60;
      let ss = Number(hh) + Number(mm) + time.getSeconds();
      return ss;
    },
    //移动
    move(e) {
      let startTime = new Date().getTime(); //获取当前按下时的时间
      let odiv = e.target; //获取目标元素
      const maxLeft = 0; //left最大值,40是目标元素的宽
      const maxTop = window.outerHeight - 71; //top最大值，40是目标元素的高
      let t = e.targetTouches[0];
      let x = t.clientX - t.target.offsetLeft; //触碰点当前距离事件源的左距
      let y = t.clientY - t.target.offsetTop; //触碰点当前距离事件源的上距
      document.ontouchmove = (e) => {
        //鼠标按下并移动的事件
        //用鼠标的位置减去鼠标相对元素的位置(默认20)，得到元素的位置
        const touch = e.changedTouches[0];
        let left = touch.clientX - x;
        let top = touch.clientY - y;
        // console.log(top)
        // 设置移动范围
        if (left <= 0) left = 0;
        if (left >= maxLeft) left = maxLeft;
        // if(top <= 71) top = 71 //最小为头部底边
        if (top >= maxTop) top = maxTop;
        //移动当前元素(改变定位)
        // odiv.style.left = left + 'px'
        odiv.style.top = top + "px";
      };
      document.ontouchend = () => {
        document.ontouchmove = null;
        e.preventDefault();
        var nowTime = new Date().getTime(); //松开时的时间
        // 小于300ms可以识别为点击事件的范围,最好设置为50
        if (nowTime - startTime < 120 && !this.isluckDraw) {
          this.showcoupon = true;
        } else {
          this.showcoupon = false;
        }
      };
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-img2 {
  width: 375px;
  height: auto;
  border-radius: 11px;
  background: transparent !important;

  /deep/ .van-dialog__content {
    .showcoupon {
      position: relative;
      .popup_con {
        padding: 154px 75px 60px 75px;
        width: 375px;
        // height: 368px;
        // background: url("../../../cmmon/image/ndj@2x.png") no-repeat;
        background-size: 100% 100% !important;
      }
      .popup_con2 {
        padding: 124px 75px 60px 75px;
        width: 375px;
        height: 432px;
        background: url("../../../cmmon/image/ndj2@2x.png") no-repeat;
        background-size: 100% 100% !important;
      }
      .num {
        margin-bottom: 16px;
        .label {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #222222;
          line-height: 19px;
        }
        .value {
          font-size: 16px;
          font-family: OPPOSans-H, OPPOSans;
          font-weight: normal;
          color: #ff3f3e;
          line-height: 25px;
        }
      }

      .icon_close {
        width: 30px;
        height: 30px;
        margin-bottom: 20px;
        margin-left: 80%;
      }
      .complated {
        position: absolute;
        top: 254px;
        left: 96px;
        img {
          height: 21px !important;
          width: 69px !important;
        }
      }

      img {
        width: 375px;
        height: 368px;
      }

      .btns {
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 137px;
        height: 44px;
        border-radius: 24px;
        background: #ba8467;
        font-size: 18px;
        color: #f9f9f9;
      }
    }
  }
}
.popupIcon {
  position: fixed;
  right: 0px;
  bottom: 220px;
  z-index: 13;
  width: 67px;
  height: 71px;
}
.move-box {
  animation: moveright 1s;
  animation-fill-mode: both;
}
@keyframes moveright {
  0% {
    right: 0;
  }
  100% {
    right: -67px;
  }
}
.move-box2 {
  animation: moveleft 1s;
}
@keyframes moveleft {
  0% {
    right: -67px;
  }
  100% {
    right: 0px;
  }
}
.rmpopup {
  /deep/.van-popup__close-icon--top-right {
    top: 19px;
  }
}
.guige {
  background: url("../../../cmmon/image/rmtitle_bg@2x.png") no-repeat;
  background-size: 100% 100%;
  height: 64px;
  line-height: 64px;
  padding-left: 20px;
  p {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
  }
}
.conBox {
  overflow-y: scroll;
  height: 90%;
  position: relative;
  .ordertotal {
    position: absolute;
    top: 189px;
    right: 17px;
    width: 180px;
    text-align: center;
    font-size: 24px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #e86d6d;
    line-height: 25px;
  }
}
.lottery_draw {
  margin-top: 14px;
  .num {
    .title {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #2d72f2;
      line-height: 19px;
    }
  }
}
</style>
