<template>
  <div>
    <div class="allscroll" @scroll="handleScroll">
      <div class="msg">
        <div class="msg-left">
          <div class="hp">
            <img :src="defaultAvatar" v-real-img="avatar" alt />
          </div>
          <div>
            <div class="agsname">欢迎您,{{ agentsMsg.name }}</div>
            <div class="levels_group">
              <img
                class="levels_icon"
                v-show="agentsMsg.follow && agentsMsg.follow.level == '200'"
                src="../../cmmon/image/me1/Sx@2x.png"
                alt=""
              />
              <img
                class="levels_icon"
                v-show="agentsMsg.follow && agentsMsg.follow.level == '215'"
                src="../../cmmon/image/me1/Hy@2x.png"
                alt=""
              />
              <img
                class="levels_icon"
                style="margin-left: 10px"
                v-show="agentsMsg.follow && agentsMsg.follow.level == '220'"
                src="../../cmmon/image/me1/yk@2x.png"
                alt=""
              />
              <img
                class="levels_icon"
                style="margin-left: 10px"
                v-show="agentsMsg.follow && agentsMsg.follow.level == '210'"
                src="../../cmmon/image/me1/tgz@2x.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="msg-right-content">
          <div class="msg-center msg-right">
            <div class="kefus" @click="tomessagepush">
              <img src="../../cmmon/image/index1/dibu/xiaoxi@2x.png" alt />
            </div>
            <span class="span2">消息</span>
          </div>
          <a
            :href="url"
            v-if="
              agentsMsg.level == 10 ||
              (agentsMsg.follow && agentsMsg.follow.level == 200) ||
              agentsMsg.acstate == '未确认'
            "
          >
            <div class="msg-right">
              <div class="kefus">
                <img src="../../cmmon/image/index1/kefu.png" alt />
              </div>
              <span class="span2">客服</span>
            </div>
          </a>
        </div>
      </div>
      <!-- 轮播 -->
      <div class="Shuffling">
        <van-swipe indicator-color="white" :autoplay="3000">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <div @click="toLink(item)">
              <img :src="item.key" />
              <img :src="defaultAvatar" v-real-img="item.key" alt />
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- 导航 -->
      <div class="searchBar">
        <div
          :class="searchBarFixed == true ? 'isFixed' : 'nav'"
          style="margin-bottom: 10px"
        >
          <div class="nav-list nav-lista" @click="setScroolTop('activity')">
            <a>
              <div class="list-img"></div>
              <span>活动中心</span>
            </a>
          </div>
          <div class="nav-list nav-listb" @click="towc" v-show="false">
            <div class="list-img"></div>
            <span>朋友圈素材</span>
          </div>
          <div class="nav-list nav-listf" @click="showtips">
            <!-- <div class="nav-list nav-listf" @click="$router.push('/replacement')"> -->
            <div class="list-img"></div>
            <span>换购活动</span>
          </div>
          <!-- <div
            v-if="agentsMsg.phone !== '13302980109'"
            class="nav-list nav-listc"
            @click="$router.push('/dy/index')"
          >
            <div class="list-img"></div>
            <span>抖音服务</span>
          </div> -->
          <!-- <div class="nav-list nav-listd" @click="$router.push('/product')">
            <div class="list-img"></div>
            <span>产品大全</span>
          </div> -->
          <div class="nav-list nav-liste" @click="setScroolTop('system')">
            <div class="list-img"></div>
            <span>系统消息</span>
          </div>
        </div>
      </div>
      <!-- 表彰banner -->
      <div class="prize-banner" v-if="prizeBanner.is_enable">
        <div class="prize-img" @click="$router.push('/prizeManage')">
          <img :src="prizeBanner.img" alt="" />
        </div>
      </div>
      <!-- 限发地区 -->
      <!-- <div class="limiting">
          <div class="limiting_con">
            <div class="limiting_left">
              <img src="../../cmmon/image/index1/tb@2x.png"/>
              <span>限发区域查询</span>
            </div>
            <div class="limiting_right" @click="limitingList()">
              <span>立即查询</span>
            </div>
          </div>
      </div> -->

      <!-- 妍可颜活动中心 -->
      <div class="activity_center" v-if="ykypromotionList.length > 0">
        <div
          class="ykyactive"
          v-for="(item, index) in ykypromotionList"
          :key="index"
        >
          <!-- <img class="label" src="../../cmmon/image/ykylabel@2x.png" alt="" /> -->
          <div class="label_box">
            <div class="label" v-if="item.mode == '1111' || item.mode == '3'">
              <span>{{ mode[item.mode] }}</span>
            </div>
            <div class="triangle-left"></div>
            <div class="triangle_box"></div>
          </div>
          <div style="position: relative">
            <img
              class="banner"
              :src="item.image"
              alt=""
              @click="toykypro(item)"
            />
            <div class="copytext">
              <span :id="foo + index + 'yky'" class="none">{{ item._id }}</span>
              <span
                :class="copy_bt + 'yky' + index"
                :data-clipboard-target="'#' + foo + index + 'yky'"
                @click="copy(index, item, 'yky')"
                v-if="
                  agentsMsg.follow &&
                  (agentsMsg.follow.level == '200' ||
                    agentsMsg.follow.level == '210') &&
                  item.mode == '3'
                "
                >复制链接
              </span>
            </div>
          </div>
          <div class="classname" @click="toykypro(item)">
            {{ item.name }}
          </div>

          <div class="btn" @click="toykypro(item)">
            <span>立即参与</span>
          </div>
        </div>
      </div>
      <div class="get_more" v-if="ykyactivityTotal > 3 && getMoreNum !== 1">
        <span @click="getykyActivityMore">查看更多</span>
        <i></i>
      </div>
      <!-- <div
        class="activity_center"
        id="activity"
        v-if="ykypromotionList.length > 0"
      >
        <div class="activity_content">
          <h3>妍可颜活动中心</h3>
          <div
            class="activity_info"
            v-for="(item, index) in ykypromotionList"
            :key="index"
          >
            <div class="activity_img">
              <img :src="item.image || defaultBj" alt="" />
              <i
                class="date_icon"
                v-if="item.ednTips && item.end_duration !== 0 && !item.isend"
                >即将结束</i>
              <i class="date_icon" v-if="item.isend">已经结束</i>
               <i class="link_icon">复制链接</i>
              <span :id="foo + index" class="none">{{ item.id }}</span>
              <span
                :class="copy_bt + index"
                :data-clipboard-target="'#' + foo + index"
                @click="copy(index, item)"
                v-if="kind == '代理商'"
                >复制链接
              </span>
            </div>
            <div class="activity_context">
              <h4>{{ item.explain }}</h4>
              <div v-if="item.own_count" @click="give(item)">
                <span class="buy_num"
                  ><i></i>可购数量<b>{{ item.own_count }}</b></span
                >
                <span v-if="item.isGive" class="give">点击转赠</span>
              </div>
              <div
                class="date_bottom"
                :style="item.own_count ? 'marginTop:-10px' : ''"
              >
                <div class="data">
                  <p>开始：{{ item.start_at }}</p>
                  <p>结束：{{ item.end_at }}</p>
                </div>
                <div class="btn" @click="toykypro(item)">
                  // <i class="type_icon" v-if="item.payType.length === 2">微信/余额</i>
                  <i
                    class="type_icon"
                    v-if="
                      item.pay_mode.length === 1 && item.payType[0] === '余额购'
                    "
                    >余额支付</i
                  >
                  <i
                    class="type_icon"
                    v-if="
                      item.pay_mode.length === 1 && item.payType[0] === '促销'
                    "
                    >微信支付</i
                  >
                  <span>立即参与</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 活动中心 -->
      <div
        class="activity_center"
        id="activity"
        v-if="promotionList.length > 0"
      >
        <div class="activity_content">
          <h3>活动中心</h3>
          <div
            class="activity_info"
            v-for="(item, index) in promotionList"
            :key="index"
          >
            <div class="activity_img">
              <img :src="item.banner || defaultBj" alt="" />
              <i
                class="date_icon"
                v-if="item.ednTips && item.end_duration !== 0 && !item.isend"
                >即将结束</i
              >
              <i class="date_icon" v-if="item.isend">已经结束</i>
              <!-- <i class="link_icon">复制链接</i> -->
              <span :id="foo + index" class="none">{{ item.id }}</span>
              <span
                :class="copy_bt + index"
                :data-clipboard-target="'#' + foo + index"
                @click="copy(index, item, '')"
                v-if="kind == '代理商'"
                >复制链接
              </span>
            </div>
            <div class="activity_context">
              <h4>{{ item.explain }}</h4>
              <div v-if="item.own_count" @click="give(item)">
                <span class="buy_num"
                  ><i></i>可购数量<b>{{ item.own_count }}</b></span
                >
                <span v-if="item.isGive" class="give">点击转赠</span>
              </div>
              <div
                class="date_bottom"
                :style="item.own_count ? 'marginTop:-10px' : ''"
              >
                <div class="data">
                  <p>开始：{{ item.starttime }}</p>
                  <p>结束：{{ item.stoptime }}</p>
                </div>
                <div class="btn" @click="topro(item)">
                  <!-- <i class="type_icon" v-if="item.payType.length === 2">微信/余额</i> -->
                  <i
                    class="type_icon"
                    v-if="
                      item.payType.length === 1 && item.payType[0] === '余额购'
                    "
                    >余额支付</i
                  >
                  <i
                    class="type_icon"
                    v-if="
                      item.payType.length === 1 && item.payType[0] === '促销'
                    "
                    >微信支付</i
                  >
                  <span>立即参与</span>
                </div>
              </div>
            </div>
          </div>
          <div class="get_more" v-if="activityTotal > 3 && getMoreNum !== 1">
            <span @click="getActivityMore">查看更多</span>
            <i></i>
          </div>
        </div>
      </div>
      <!-- 系统消息 -->
      <div class="activity_center" id="system">
        <div class="activity_content">
          <h3>系统消息</h3>
          <div
            class="activity_info system_info"
            v-for="item in systemList"
            :key="item._id"
            @click="goMessageList(item)"
          >
            <div class="system_icon">
              <!-- <img v-if="item.name === '产品消息'" src="../../cmmon/image/index1/dibu/产品信息@2x.png" alt="">
              <img v-if="item.name === '通知公告'" src="../../cmmon/image/index1/dibu/通知公告@2x.png" alt="">
              <img v-if="item.name === '发货进度表'" src="../../cmmon/image/index1/dibu/发货进度@2x.png" alt=""> -->
              <img :src="item.banner" alt="" />
            </div>
            <div class="msg_info">
              <div class="msg_text">
                <h4>{{ item.name }}</h4>
                <p>{{ item.remark }}</p>
                <!-- <p>{{ item.express }}</p> -->
              </div>
              <div class="right_arrow"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="zwz"></div>
      <tabber />
      <!--弹框管理-->
      <van-dialog
        v-model="mydialogshow"
        :title="mydialogitem.title"
        :confirmButtonText="mydialogitem.msgtext"
        :show-cancel-button="mydialogitem.hascancle"
        @confirm="dialogconfirm"
        @cancel="dialogcancle"
      >
        <div class="showkuc">
          {{ mydialogitem.message }}
          <div class="unreadmsg" v-show="mydialogitem.showunreadmsg">
            <div class="msgtips">
              <img src="../../cmmon/image/index1/Wdxx@2x.png" alt />
            </div>
            <p>未读消息</p>
            <ul>
              <li v-for="(item, index) in this.unreadList" :key="index">
                <span class="times">{{ item.createat }}</span>
                <div class="msg_text">
                  <div class="msg_text" v-if="item.msg">
                    {{ item.msg }}
                    <span class="symbol"></span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </van-dialog>
      <!-- 表彰弹框 -->
      <van-dialog
        class="dialog-img"
        v-model="popupShow"
        :show-confirm-button="false"
        :show-cancel-button="false"
      >
        <div class="showtans" @click="goPrize">
          <img :src="popupImg" />
          <!-- <van-button class="btns" @click="goPrize">立即查看</van-button> -->
        </div>
        <i class="icon_close" @click="closePopup"></i>
      </van-dialog>
      <!-- 首页弹窗 -->
      <van-dialog
        class="dialog-img dialog-img-milk"
        v-model="milkShow"
        :show-confirm-button="false"
        :show-cancel-button="false"
      >
        <van-swipe class="my-swipe" indicator-color="white">
          <van-swipe-item v-for="(Popup, index) in indexPopup" :key="index">
            <div class="showtans" @click="goDetl('indexPopup', Popup)">
              <img :src="Popup ? Popup.key : ''" />
            </div>
          </van-swipe-item>
        </van-swipe>
        <i class="icon_close" @click="closeMilkPopup"></i>
      </van-dialog>
      <!-- 活动弹窗 -->
      <van-dialog
        v-for="(item, index) in isPosterList"
        :key="index"
        class="dialog-img dialog-img-milk"
        v-model="item.activityShow"
        :show-confirm-button="false"
        :show-cancel-button="false"
      >
        <div class="showtans" @click="goDetl('activity', item)">
          <img :src="item.poster" />
        </div>
        <i class="icon_close" @click="closeActivityPopup(item, index)"></i>
      </van-dialog>
      <!-- 每月秒杀 -->
      <!-- <Mspopups
        v-if="monthpopupshow"
        ref="mypopups"
        :icon="require('@/cmmon/image/2th@2x.png')"
        type="3"
        :bg1="require('@/cmmon/image/2th_bg@2x.png')"
        formid1="65b8eb7c2e801643747493a4"
        formid2=""
        formid1white="65b8ec1a2e801643747493a5"
        activeid=""
        bottom="330px"
      ></Mspopups> -->
      <!-- 客户预存 -->
      <!-- <img
        v-if="agentsMsg.follow && agentsMsg.follow.level == 200"
        class="nhzj"
        src="../../cmmon/image/khyc.png"
        alt=""
        :class="iskhyc ? 'move-box' : 'move-box2'"
        :style="{ bottom: bottom }"
        @touchstart.prevent="move($event, 'khyc')"
      /> -->
      <!-- 幸运扭蛋 -->
      <!-- <Mspopups
        ref="mypopups"
        :icon="require('@/cmmon/image/draw_icon@2x.png')"
        type="2"
        :bg1="require('@/cmmon/image/ndj@2x.png')"
        :bg2="require('@/cmmon/image/ndj2@2x.png')"
        formid1="6567e20fc59dcdd1d46e187b"
        formid2="6567e285c59dcdd1d46e187c"
        activeid="656801bfa834bc236d52886a"
        bottom="220px"
      ></Mspopups> -->
      <!-- 年会资格弹窗 -->
      <!-- <van-dialog
        v-model="isshownh"
        :show-confirm-button="false"
        :show-cancel-button="false"
        style="width: 100%; background-color: transparent"
      >
        <div class="popbox">
          <img
            class="icon_close"
            src="@/cmmon/image/Tc_gb@2x.png"
            alt=""
            @click="isshownh = false"
          />
          <div class="imgwarp">
            <img v-if="nhdata.award" src="../../cmmon/image/zj@2x.png" alt="" />
            <img v-else src="../../cmmon/image/wzj@2x.png" alt="" />
            <div class="lable_item">您2023年下单数据达</div>
            <div class="num_item">
              {{ nhdata.value }}
            </div>
            <div v-if="nhdata.award">
              <div class="lable_item">恭喜您获得2024年会参与资格</div>
              <div class="lable_item">
                可参与抽奖<span class="num_item"> {{ nhdata.award }} </span>次
              </div>
              <div class="lable_item">年会不见不散~</div>
            </div>
            <div v-else class="wzj">
              <div class="lable_item">很遗憾，您未获得</div>
              <div class="lable_item">2024年会参与资格</div>
              <div class="lable_item">感谢您的参与~</div>
            </div>
            <div class="bottomtips">
              <div class="tipslable">计算方式：</div>
              <div style="text-align: left;">
                得乐云绩效产品（其中比得兔按50%核算）+
                <br/>妍可颜小程序业绩+大健康小程序业绩
              </div>
            </div>
          </div>
        </div>
      </van-dialog> -->
      <!-- 柳州会议中奖悬浮 -->
      <!-- <img
        class="nhzj egg"
        src="../../cmmon/image/twits/砸金蛋悬浮.png"
        alt=""
        :class="isshownh ? 'move-box' : 'move-box2'"
        style=" bottom: 300px "
        @touchstart.prevent="move($event,'zjd')"
        @click="toegg"
      /> -->
      <!-- 协议弹窗 -->
      <van-popup
        v-model="isspecial"
        position="bottom"
        :close-on-click-overlay="false"
        :style="{ height: '70%' }"
      >
        <div class="contract">
          <h2 class="contract_title">{{ contractText.company_name }}</h2>
          <div class="content" v-html="contractText.content"></div>
          <div class="contract_btn">
            <van-button plain type="info" @click="handelsubmit(1)"
              >同意</van-button
            >
            <van-button plain type="default" @click="handelsubmit(2)"
              >拒绝</van-button
            >
          </div>
        </div>
      </van-popup>
      <van-dialog
        v-model="isshowlimiting"
        :show-confirm-button="false"
        :show-cancel-button="false"
      >
        <div class="showtan show_lim">
          <p class="title titles">是否需要跳转至‘我的订单’查看限发商品</p>
          <div class="show_btns show_btnslim">
            <van-button @click="limiting">查询限发地区</van-button>
            <van-button @click="order">查看订单</van-button>
          </div>
        </div>
      </van-dialog>
      <van-dialog
        v-model="tabisshow"
        :show-confirm-button="false"
        :show-cancel-button="false"
      >
        <div class="showtan show_lim">
          <div class="dialog-imgs" @click="tabisshow = false">
            <i class="icon_close"></i>
          </div>
          <p class="title titles">
            🔥成人纸尿裤（银装舒适款）限时预售🔥<br />
            👉一次性下单每满10箱送1箱 <br />
            👉一次性下单满20箱送￥99.9宣传册5本（每个ID限领1份）<br />
            <br />
            最后（<span style="color: red">{{
              new Date("2023-08-15").getDate() - new Date().getDate() + 1
            }}</span
            >）天！活动仅有一次！！
          </p>
          <div class="show_btns show_btnslim">
            <!-- <van-button @click="limiting">查询限发地区</van-button>
            <van-button @click="order">查看订单</van-button> -->
          </div>
        </div>
      </van-dialog>
      <!-- 备案地址 -->
      <address-temp
        :address-popup="showAddresspick"
        @closePopup="showAddresspick = false"
        @getAddr="getAddr"
        :addr="addrList"
        :sendList="sendProvince"
        istitle="true"
      />
      <!-- 文字弹窗 -->
      <van-dialog
        v-model="showText"
        :show-confirm-button="false"
        :show-cancel-button="false"
      >
        <div class="showtan show_lim">
          <van-icon
            name="close"
            class="closeicon"
            size="24px"
            color="#666"
            @click="closeDialog"
          />
          <p class="con_title">专属福利</p>
          <p class="con_center">满499元赠送4款营养品体验装（各1盒）</p>
          <p class="con_center">购物车换购赠品</p>
          <div class="con_btns">
            <van-button @click="toykypro(popActive, 'single')"
              >去下单</van-button
            >
          </div>
        </div>
      </van-dialog>
      <!-- 核销弹窗 -->
      <van-dialog
        v-model="showhxpop"
        :show-confirm-button="false"
        :show-cancel-button="false"
      >
        <div class="showtan show_lim">
          <van-icon
            name="close"
            class="closeicon"
            size="24px"
            color="#666"
            @click="showhxpop = false"
          />
          <p class="hx_title">领取成功~</p>
          <p class="hx_center">超值体验库任你选</p>
          <div class="con_btns hx_btn">
            <van-button @click="toproduct">去看看</van-button>
          </div>
        </div>
      </van-dialog>
      <!-- 回到顶部 -->
      <backTop :show="backtopshow" :scrollDiv="scrollDiv"></backTop>
      <popups ref="mychild"></popups>
    </div>
  </div>
</template>

<script>
import tabber from "../../components/common/tabbar.vue";
import http from "../../cmmon/js/http.js";
import https from "../../cmmon/js/newhttps.js";
import des from "../../cmmon/js/des.js";
import backTop from "../common/backTop";
import popups from "./common/givepopup.vue";
import Mspopups from "./common/MS_popup.vue";
let Base64 = require("js-base64").Base64;
import AddressTemp from "../common/address.vue";
import { Toast } from "vant";
let wx = require("weixin-js-sdk");

export default {
  name: "index",
  components: {
    tabber,
    backTop,
    popups,
    Mspopups,
    AddressTemp,
  },
  data() {
    return {
      showText: false,
      popActive: {},
      iskhyc: false,
      addrList: [],
      sendProvince: [],
      bottom: "200px",
      isshownh: false,
      nhdata: {},
      mode: {
        3: "智慧零售专区",
        1111: "妍可颜专区",
      },
      popupShow: false,
      tabisshow: false,
      showcustomer: true,
      ceshi: false,
      piclist: [],
      defaultAvatar: [require("../../cmmon/image/toux.png")], //默认头像
      defaultBj: [require("../../cmmon/image/index1/loginbg.jpg")],
      avatar: "",
      url: "",
      bannerList: [],
      agentsMsg: {},
      navigatorObj: {},
      des: "Ckil845@#$%^_=258Lo{,.}",
      hot01: "",
      hot02: "",
      hot03: "",
      unreadnum: 1,
      jwttoken: sessionStorage.getItem("jwttoken"),
      arr: [
        "凯儿得乐强势入围行业榜单TOP10，全民火热投票中，一起为果C助力!",
        "凯儿得乐2018年度公益启动暨新品发布会圆满成功！",
        "凯儿得乐推出朵瑞诗面膜，微乳化技术突破补水瓶颈",
      ],
      shoplist: [],
      //弹框选项
      dialogmessage: [],
      mydialogshow: false,
      mydialogitem: {},
      unreadList: [],
      showindexmsg: "",
      showunreadmsg: false,
      SpecialSendGoodsOrder: 0,
      searchBarFixed: false,
      scrollDiv: "",
      show: false,
      systemList: [],
      promotionList: [],
      ykypromotionList: [], //妍可颜
      SeckillList: [],
      GroupList: [],
      FranchiseList: [],
      franchisestore: false,
      getMoreNum: 0,
      copy_bt: "copy_bt",
      foo: "foo",
      kind: "",
      activityTotal: 0,
      ykyactivityTotal: 0, //妍可颜
      backtopshow: false,
      popupImg: "",
      prizeBanner: {},
      isshowlimiting: false,
      milkShow: false,
      isPosterList: [], //开启弹窗的活动列表
      activityShow: false,
      activityPoster: "",
      indexPopup: null,
      shopname: "",
      allPromotionList: [],
      ykyallPromotionList: [],
      showlabels: false,
      showactives: false,
      showcoupon: false, //消费券
      couponData: {
        value: 0,
        award: 0,
        nextValue: 10000,
        nextAward: 0,
      },
      msItem: {},
      isformshow: false,
      showAddresspick: false,
      monthpopupshow: true,
      //协议
      contractText: "",
      isspecial: false,
      is_regional_partner: "",
      is_regional_sellers: "",
      isshowaActivePop: "",
      showhxpop: false,
    };
  },
  async created() {
    this.isshowaActivePop = sessionStorage.getItem("isshowaActivePop")
      ? true
      : false;
    this.navigatorObj = {
      appVersion: navigator.appVersion,
      userAgent: navigator.userAgent,
      platform: navigator.platform,
    };
    this.navigatorObj = des.encryptByDES(
      JSON.stringify(this.navigatorObj),
      this.des
    );
    if (JSON.parse(sessionStorage.getItem("agentsMsg"))) {
      this.kind = JSON.parse(sessionStorage.getItem("agentsMsg")).kind;
    }
    await this.ajaxPopup();
    await this.getOid();
    await this.getPrizeBanner();
    await this.getPromotionList(3);
    await this.getykyPromotionList(3);
    await this.getAllPromotionList();
    await this.getykyAllPromotionList();
    await this.goNotice();
    await this.getshowcoupon();
    await this.ajaxContractbyagent();
    await this.ajaxBanner();
    // this.getallorderdata();
  },
  async mounted() {
    this.scrollDiv = document.getElementsByClassName("allscroll")[0];
    if (this.agentsMsg.avatar) {
      this.avatar = this.agentsMsg.avatar;
    } else if (sessionStorage.getItem("avatar")) {
      this.avatar = sessionStorage.getItem("avatar");
    } else {
      this.avatar = this.defaultAvatar;
    }
    await this.getunmessage();

    await this.ajaxProductList();
    await this.getuserlabel();
    this.showhxpop = this.$route.query.type == "hx" ? true : false;
    // await this.getcoupondata();
  },
  methods: {
    toegg() {
      this.$router.push({
        path: "/twist/goldenegg",
        query: {
          id: "6621df24a30dc39d43fafbbe",
        },
      });
    },
    closeDialog() {
      this.showText = false;
      sessionStorage.setItem("isshowaActivePop", true);
    },
    tokh() {
      this.$router.push({
        path: "/me",
        query: {
          from: "khyc",
        },
      });
    },
    //查询是否需要展示合伙人协议
    getuserlabel() {
      https.get("user/app/regional_users").then((res) => {
        if (res.data && res.data == 2) {
          this.isspecial = true;
        }
      });
      https.get("user/app/follow?scope=3").then((res) => {
        if (res.data) {
          this.is_regional_partner = res.data.is_regional_partner;
          this.is_regional_sellers = res.data.is_regional_sellers;
        }
      });
    },
    //取协议
    ajaxContractbyagent() {
      // console.log(this.agentsMsg, "agentsMsg");
      https
        .get(
          "contract/public/contracts?company_name=" +
            this.agentsMsg.contractbankname +
            "&agreement_name=区域销售者或区域合伙人协议"
        )
        .then((res) => {
          if (res.code === 0) {
            this.contractText = res.data;
          }
        })
        .catch((err) => {});
    },
    //协议提交
    async handelsubmit(type) {
      if (type == 1) {
        let res = await https.put("/user/app/regional_users_state/1");
        if (res) {
          this.isspecial = false;
          Toast.allowMultiple();
          if (this.is_regional_partner == 1) {
            const toast1 = Toast({
              message: "您已成为城市合伙人",
              position: "top",
            });
          }
          if (this.is_regional_sellers == 1) {
            const toast2 = Toast("您已成为区域销售者");
          }
        }
        this.submitApply();
      } else {
        let ok = await this.$dialog.confirm({
          title: "提示",
          message: "拒绝后无法成为区域销售者",
          cancelButtonText: "返回",
          cancelButtonColor: "#222222FF",
          confirmButtonText: "确认拒绝",
        });
        if (ok) {
          let res = await https.put("/user/app/regional_users_state/2");
          if (res.message) {
            this.isspecial = false;
          }
        }
      }
    },
    // 同意
    async submitApply() {
      var data = {
        agent_id: this.agentsMsg._id,
        name: this.agentsMsg.name,
        identity: this.agentsMsg.idcardno,
        company_name: this.agentsMsg.contractbankname,
        agreement_name: ["区域销售者或区域合伙人协议"],
        sign_history_type: "sign_history_partner",
      };
      try {
        let res = await https.post("contract/app/sign_contract", data);
        if (res) {
        } else {
          Dialog.alert({
            title: "提示",
            message: res.errmsg,
          }).then(() => {});
        }
      } catch (e) {}
    },
    showtips() {
      this.$toast("功能建设中");
    },
    //打开弹窗11月秒杀
    openpopup() {
      this.showcoupon = true;
    },
    getshowcoupon() {
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1;
      let d = new Date();
      let ymd = d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate();
      let nowDayStart = new Date(ymd + " 00:00:00").getTime();
      let nowDayEnd = new Date(ymd + " 23:59:59").getTime();
      let n = localStorage.getItem("coupontime");
      let currentTime = n ? Number(localStorage.getItem("coupontime")) : "";
      let timeover = false;
      if (
        !currentTime ||
        currentTime <= nowDayStart ||
        (nowDayStart <= currentTime <= nowDayEnd &&
          localStorage.getItem("couponCount") != 1)
      ) {
        timeover = true;
        localStorage.setItem("coupontime", new Date().getTime());
      }
      if (month != 2 || year != 2024) {
        this.monthpopupshow = false;
        return;
      }
      if (month == 2 && year == 2024 && timeover) {
        this.$store.commit("setshowcoupon", true);
      } else {
        this.$store.commit("setshowcoupon", false);
      }
    },
    closecoupon() {
      this.showcoupon = false;
      localStorage.setItem("couponCount", 1);
    },
    //移动
    move(e, type) {
      let startTime = new Date().getTime(); //获取当前按下时的时间
      let odiv = e.target; //获取目标元素
      const maxLeft = 0; //left最大值,40是目标元素的宽
      const maxTop = window.outerHeight - 71; //top最大值，40是目标元素的高
      let t = e.targetTouches[0];
      let x = t.clientX - t.target.offsetLeft; //触碰点当前距离事件源的左距
      let y = t.clientY - t.target.offsetTop; //触碰点当前距离事件源的上距
      document.ontouchmove = (e) => {
        //鼠标按下并移动的事件
        //用鼠标的位置减去鼠标相对元素的位置(默认20)，得到元素的位置
        const touch = e.changedTouches[0];
        let left = touch.clientX - x;
        let top = touch.clientY - y;
        // console.log(top)
        // 设置移动范围
        if (left <= 0) left = 0;
        if (left >= maxLeft) left = maxLeft;
        // if(top <= 71) top = 71 //最小为头部底边
        if (top >= maxTop) top = maxTop;
        //移动当前元素(改变定位)
        // odiv.style.left = left + 'px'
        odiv.style.top = top + "px";
      };
      document.ontouchend = () => {
        document.ontouchmove = null;
        e.preventDefault();
        var nowTime = new Date().getTime(); //松开时的时间
        // 小于300ms可以识别为点击事件的范围,最好设置为50
        if (nowTime - startTime < 120) {
          this.isshownh = true;
          if (type == "khyc") {
            this.tokh();
          } else if (type == "zjd") {
            this.toegg();
          }
        }
      };
    },
    //取年会数据
    async getallorderdata() {
      let res = await https.get(
        "/report/app/wx/sale_result?cid=6567e285c59dcdd1d46e187c"
      );
      this.nhdata = res.data ? res.data[0] : {};
      if (this.nhdata.value) {
        this.nhdata.value = this.nhdata.value.toFixed(2);
      }
    },
    //获取11月秒杀数据
    async getcoupondata() {
      let res = await https.get(
        "/report/app/wx/sale_result?cid=65586f5e59a7e600978c18c2"
      );
      if (res.data) {
        this.couponData = res.data[0];
      }
    },
    //11秒杀活动
    gogoodList() {
      localStorage.setItem("couponCount", 1);
      // this.$router.push({
      //   path: "/couponGoods",
      // });
      this.topro(this.msItem);
    },
    // 每天首次进入弹窗一次
    milkIsShow() {
      let d = new Date();
      let ymd = d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate();
      let nowDayStart = new Date(ymd + " 00:00:00").getTime();
      let nowDayEnd = new Date(ymd + " 23:59:59").getTime();
      let n = localStorage.getItem("nowtime");
      let currentTime = n ? Number(localStorage.getItem("nowtime")) : "";
      if (
        !currentTime ||
        currentTime <= nowDayStart ||
        (nowDayStart <= currentTime <= nowDayEnd &&
          localStorage.getItem("milkCount") != 1 &&
          this.indexPopup.length > 0)
      ) {
        // console.log(this.milkShow, "this.milkShow");
        this.milkShow = true;
        // console.log(this.milkShow, "this.milkShow");
        localStorage.setItem("nowtime", new Date().getTime());
      }
    },

    timeIsShow() {
      let d = new Date().getTime();
      localStorage.setItem("timeIsShow", "1");
      // let nowDayStart = new Date('2023-05-12 ' + ' 00:00:00').getTime()
      let nowDayEnd = new Date("2023-08-16" + " 00:00:00").getTime();
      if (d < nowDayEnd) {
        this.tabisshow = true;
      }
    },

    goDetl(type, item) {
      if (type === "indexPopup") {
        localStorage.setItem("milkCount", 1);
        // this.$router.push('/productdetails?_id=634ccce73bd045c68ec14095&thumbnail=https://caredaily-cloud.oss-cn-hangzhou.aliyuncs.com/product/202210/634ccce01aa6ea07a8d4165f.png')
        // this.$router.push('/product?categoryid=638e9faa30a3047b25214c46')
        // location.href = item.val
        this.$router.push({ path: item.val });
      } else {
        localStorage.setItem("activityId", item.id);
        this.topro(item);
      }
    },
    getPrizeBanner() {
      https.get("/article/app/banners").then((res) => {
        if (res.message === null || res.message == undefined) {
          this.prizeBanner = res.data.find((el) => {
            return el.kind === "commend";
          });
          let popupImg = res.data.find((el) => {
            return el.kind === "popup";
          });
          const oldDate = JSON.parse(
            JSON.stringify(localStorage.getItem("update"))
          );
          if (localStorage.getItem("update") != popupImg.update_at) {
            localStorage.setItem("update", popupImg.update_at);
          }
          let now = new Date().getTime();
          let start = new Date(popupImg.start).getTime();
          let end = new Date(popupImg.end).getTime();
          if (now >= start && now <= end && popupImg.is_enable) {
            if (popupImg.update_at && oldDate != popupImg.update_at) {
              this.popupShow = true;
              this.popupImg = popupImg.img;
            }
            if (localStorage.getItem("prizeCount") != 1) {
              this.popupShow = true;
              this.popupImg = popupImg.img;
            }
          }
        } else {
          this.$toast(res.message);
        }
      });
    },
    goPrize() {
      localStorage.setItem("prizeCount", 1);
      this.$router.push("/prizeManage");
    },
    closePopup() {
      this.popupShow = false;
      localStorage.setItem("prizeCount", 1);
    },
    closeMilkPopup() {
      this.milkShow = false;
      localStorage.setItem("milkCount", 1);
    },
    closeActivityPopup(item, index) {
      this.isPosterList[index].activityShow = false;
      localStorage.setItem("activityId", item.id);
    },
    // handleScroll () {
    //   var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    //   var offsetTop = document.querySelector('#searchBar').offsetTop
    //   if (scrollTop > offsetTop) {
    //     this.searchBarFixed = true
    //   } else {
    //     this.searchBarFixed = false
    //   }
    // },
    handleScroll() {
      let scrollTop = document.getElementsByClassName("allscroll")[0].scrollTop;
      this.backtopshow = scrollTop > 300 ? true : false;
    },
    towc() {
      this.$router.push("/wcmaterial");
    },
    // 消息推送
    tomessagepush() {
      this.$router.push("/Messagepush");
    },
    // 购物车商品列表
    async ajaxProductList() {
      try {
        let res = await http.get("commodity/agent/carts");
        // let res = await https.get('order/app/cart')
        if (res.data) {
          let total = 0;
          res.data.forEach((value) => {
            total += value.count;
          });
          this.shopCartCount = total;
          this.$store.commit("getShopCartCount", total ? total : null);
        } else {
          this.$store.commit("getShopCartCount", null);
        }
      } catch (e) {
        this.$toast(e.toString());
      }
    },
    // 首页弹窗
    async ajaxPopup() {
      try {
        let res = await https.get("article/app/masters?kind=popup");
        let userlabels = JSON.parse(sessionStorage.getItem("agentsMsg")).labels;
        if (res.data.length > 0) {
          // this.indexPopup = res.data[0]
          this.indexPopup = [];
          res.data.map((item) => {
            if (item.relevance == "会员标签") {
              userlabels.map((lab) => {
                if (item.agentLabels == lab) {
                  this.indexPopup.push(item);
                }
              });
            } else if (item.relevance == "促销活动") {
              this.allPromotionList.map((active) => {
                if (item.moduleId == active._id) {
                  this.indexPopup.push(item);
                }
              });
            } else {
                this.indexPopup.push(item);
            }
          });
          this.milkIsShow();
        } else {
          this.indexPopup = null;
        }
      } catch (e) {}
    },
    // banner
    async ajaxBanner() {
      try {
        let res = await https.get("article/app/masters?kind=banner");
        if (res.data) {
          let userlabels = JSON.parse(
            sessionStorage.getItem("agentsMsg")
          ).labels;
          // let arr=[]
          // res.data.forEach((item) => {
          //   let now = new Date().getTime();
          //   let start = new Date(item.start);
          //   let end = new Date(item.end);
          //   if (now >= start && now <= end) {
          //     arr.push(item);
          //   }
          // });
          this.bannerList = await res.data;
          // this.bannerList = await res.data.filter((ele) => {
          //   return (
          //     !ele.agentLabels ||
          //     (userlabels && userlabels.includes(ele.agentLabels))
          //   );
          // });
        }
      } catch (e) {
        // this.$toast(e.toString())
      }
    },
    // banner跳转
    toLink(item) {
      // console.log(item, "linktype");
      if (item.linkType === "商城小程序") {
        var wxF = false;
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf("micromessenger") == -1) {
          this.$toast("请在微信上打开");
        } else {
          //获取当前环境
          wx.miniProgram.getEnv(function (res) {
            if (res.miniprogram) {
              wxF = true;
            }
          });
          //因在getEnv作用域下,无法使用navigateTo的方法，所以放到下面
          if (wxF) {
            wx.miniProgram.navigateTo({
              url: "/pages/toC/index?links=" + encodeURIComponent(item.val),
            });
          } else {
            this.$toast("请在微信小程序上打开");
          }
        }
      } else {
        location.href = item.val;
      }
      // if (item.val) {
      //   location.href = item.val;
      // }
    },
    // 获取未读消息
    async getunmessage() {
      try {
        let res = await http.get("information/agent/messages?kind=未读&read=1");
        if (res.ok && res.data != "") {
          res.data.forEach((v) => {
            v.createat = v.createat.substring(0, 10);
          });
          this.unreadList = res.data;
          this.dialogmessage.push({
            item: "needunreadmsg",
            dialog: true,
            msgtext: "我知道了",
            hascancle: false,
            showunreadmsg: true,
          });
        }
        if (this.dialogmessage.length > 0) {
          this.dialogHandler();
        }
        this.getmessage();
      } catch (e) {
        this.$toast(e.toString());
      }
    },
    // 获取已读消息
    async getmessage() {
      try {
        let res = await http.get("information/agent/messages?kind=已读");
        if (res.ok && res.data && res.data.length > 0) {
          this.showindexmsg = res.data[0].msg;
        }
      } catch (e) {
        this.$toast(e.toString());
      }
    },
    // 活动中心
    setScroolTop(type) {
      document.querySelector(`#${type}`).scrollIntoView({
        block: "start", // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
        inline: "nearest", // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
      });
    },
    limitingList() {
      https.get("storage/app/agent_restricted").then((res) => {
        if (res.message === "1") {
          this.isshowlimiting = true;
        } else {
          this.$router.push("/limitingList");
          //  this.$toast(res.message)
        }
      });
    },
    limiting() {
      this.$router.push("/limitingList");
    },
    order() {
      sessionStorage.setItem("orderIndex", 0);
      this.$router.push("orders/order");
    },
    // 获取代理商个人信息
    async getOid() {
      try {
        // let res = await http.get("usercenter/agent/self?do=check");
        let res = await https.get("user/app/self?do=check");
        if (res.code == 0) {
          this.agentsMsg = res.data;
          this.kind = res.data.kind;
          if (this.agentsMsg.labels) {
            if (this.agentsMsg.labels.includes("limit")) {
              sessionStorage.setItem("isshowturn", true);
            } else {
              sessionStorage.setItem("isshowturn", false);
            }
          }
          if (this.agentsMsg.avatar) {
            this.avatar = this.agentsMsg.avatar;
          }
          //需要缓存的信息
          sessionStorage.setItem("oid", this.agentsMsg._id);
          sessionStorage.setItem("contractbank", this.agentsMsg.contractbank);
          if (this.agentsMsg.ispublish === true) {
            sessionStorage.setItem("ispublish", this.agentsMsg.ispublish);
          }
          sessionStorage.setItem("level", this.agentsMsg.level);
          sessionStorage.setItem("avatar", this.agentsMsg.avatar);
          sessionStorage.setItem("agentsMsg", JSON.stringify(this.agentsMsg));
          //注册或者晋级代理的特殊判断(资金待确认，冻结代理跳注册页面)
          if (
            this.agentsMsg.err === "未清算" ||
            this.agentsMsg.err === "没有付款信息"
          ) {
            this.$router.push({
              name: "registered2",
              query: {
                state: this.agentsMsg.err,
                prepayments: this.agentsMsg.prepayment,
                levelname: this.agentsMsg.levelname,
                ensurepay: this.agentsMsg.ensurepay,
                level: this.agentsMsg.level,
                remark: this.agentsMsg.remark,
              },
            });
            return;
          }
          //  else if (this.agentsMsg.acstate === "冻结") {
          //   this.$router.push({
          //     name: "registered",
          //     query: {
          //       oid: this.agentsMsg.leader,
          //       agentimg: "",
          //       agentid: this.agentsMsg._id,
          //     },
          //   });
          //   return;
          // }
          //客服跳转参数的整理
          this.url =
            window.location.protocol +
            "//" +
            window.location.host +
            "/service.html?agentid=" +
            this.agentsMsg._id +
            "&name=" +
            this.agentsMsg.name +
            "&phone=" +
            this.agentsMsg.phone +
            "&levelname=" +
            this.agentsMsg.levelname +
            "&leader=" +
            this.agentsMsg.leader +
            "&leadername=" +
            this.agentsMsg.leadername +
            "&specialfrom=" +
            this.agentsMsg.specialfrom +
            "&specialfromname=" +
            this.agentsMsg.specialfromname;

          //弹框的整理
          // ***完成首提的首席填写库存提醒
          let time =
            new Date().getTime() <= new Date("2020/11/30 23:59:59").getTime();
          if (res.data.acstate === "已审核" && res.data.needstock && time) {
            this.dialogmessage.push({
              item: "needstock",
              title: "提示",
              dialog: true,
              hascancle: false,
              url: {
                name: "eshop",
                query: {
                  item: "5fbcbed9befafae171fe2a08",
                  name: "经销商库存收集",
                  cannotsubmit: false,
                  tabShow: true,
                },
              },
              message: "为了助力12月活动请大家如实填写库存信息",
            });
          }
          if (res.data.needrecharge) {
            let obj = {
              item: "needrecharge",
              title: "提示",
              dialog: true,
              hascancle: false,
              url: { name: "accounts", query: { chooseIndex: 2 } },
              message: "您有需要补款的资金流水，请处理",
            };
            if (this.agentsMsg.isspecial === true) {
              obj.hascancle = true;
            }
            this.dialogmessage.push(obj);
          }
          if (res.data.needconfirm) {
            this.dialogmessage.push({
              item: "needconfirm",
              title: "提示",
              dialog: true,
              hascancle: true,
              url: { name: "accountcenter", query: { chooseIndex: 3 } },
              message: "您有需要确认的资金流水，请处理",
            });
          }
          if (res.data.needpublish) {
            this.dialogmessage.push({
              item: "needcompanytype",
              title: "提示",
              dialog: true,
              hascancle: false,
              url: { name: "paymethodlist", query: { onlyCompanytype: true } },
              message: "请完善对公账号企业增值税纳税人类型信息",
            });
          }

          // if (!this.limitTime) {
          //   if (res.data.needoutbank && res.data.outbanktip!='') {
          //     this.dialogmessage.push({
          //       item: "needoutbank",
          //       title: "提示",
          //       dialog: true,
          //       hascancle: true,
          //       url: { name: "paymethodlist" },
          //       message: res.data.outbanktip
          //     });
          //   }
          // }
          if (res.data.needoutbank && res.data.outbanktip != "") {
            this.dialogmessage.push({
              item: "needoutbank",
              title: "提示",
              dialog: true,
              hascancle: true,
              url: { name: "paymethodlist" },
              message: res.data.outbanktip,
            });
          }
          //月末补货提醒
          // let date = new Date();
          // let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
          // let s = lastDay.getTime() - 1000 * 60 * 60 * 24;
          // let e = lastDay.getTime() - 1000 * 60 * 60 * 24 * 3;
          // if (
          //   date.getTime() <= s &&
          //   date.getTime() >= e &&
          //   !localStorage.getItem("showTipsCount")
          // ) {
          //   this.dialogmessage.push({
          //     item: "needend",
          //     title: "【温馨提示】",
          //     dialog: true,
          //     hascancle: false,
          //     message:
          //       "销售服务费和养老金为月清月结，临近月底，请关注您的提货量。避免因考核不达标影响销售服务费及养老金发放。",
          //   });
          // }
          //月初盘点提醒
          // let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
          // s = firstDay.getTime() - 1000 * 60 * 60 * 24;
          // e = firstDay.getTime() + 1000 * 60 * 60 * 24;
          // if (
          //   date.getTime() <= e &&
          //   date.getTime() >= s &&
          //   this.agentsMsg.level === 10 &&
          //   this.agentsMsg.acstate === "已审核"
          // ) {
          //   this.dialogmessage.push({
          //     item: "needpandian",
          //     title: "【温馨提示】",
          //     dialog: true,
          //     hascancle: false,
          //     message: "注意：月初盘点"
          //   });
          // }
          if (this.dialogmessage.length > 0) {
            this.dialogHandler();
          }
          if (localStorage.getItem("timeIsShow") != "1") {
            this.timeIsShow();
          }
        }
      } catch (e) {}
    },
    getAddr(adr) {
      let params = {};
      if (adr.length > 0) {
        params = {
          province: adr[0].n,
          city: adr[1].n,
          area: adr[2].n,
          town: adr[3].n,
        };
      }

      https.put("/user/app/registered", params).then((res) => {
        this.$toast(res.message);
        this.getOid();
      });
    },
    policy() {
      this.$toast("功能正在建设中");
    },
    toshopcart() {
      this.$router.push("/shopCart");
    },
    service() {
      this.$toast("客服功能");
    },
    toproduct() {
      this.$router.push({
        path: "/newproduct",
        query: {
          type: 3,
          activity_id: "65e8a7515e8bf022f7af7b0f",
        },
      });
    },
    dialogHandler() {
      setTimeout(() => {
        if (this.dialogmessage.length > 0) {
          for (let i in this.dialogmessage) {
            if (this.dialogmessage[i].dialog === true) {
              this.mydialogitem = this.dialogmessage[i];
              this.mydialogshow = true;
              break;
            }
          }
        }
      }, 100);
    },
    async dialogconfirm() {
      if (this.mydialogitem.url) {
        this.$router.push(this.mydialogitem.url);
      } else {
        for (let i in this.dialogmessage) {
          if (this.dialogmessage[i].item === this.mydialogitem.item) {
            this.dialogmessage[i].dialog = false;
            break;
          }
        }
        this.mydialogshow = false;
        await setTimeout(() => {}, 500);
        this.dialogHandler();
      }
    },
    async dialogcancle() {
      for (let i in this.dialogmessage) {
        if (this.dialogmessage[i].item === this.mydialogitem.item) {
          this.dialogmessage[i].dialog = false;
        }
      }
      this.mydialogshow = false;
      await setTimeout(() => {}, 500);
      await this.dialogHandler();
    },
    orderlist() {
      sessionStorage.setItem("orderIndex", 1);
      this.$router.push("orders/order");
    },
    // 订单弹框
    turndown(val) {
      this.popupShow = false;
    },
    //获取妍可妍活动列表
    getykyPromotionList(size) {
      let pg = size ? `?skip=0&limit=${size}&home_page=true` : "?home_page=true";
      https
        .get(`/promotion/app/activity${pg}`)
        .then((res) => {
          if (res.changes) {
            if (res.data) {
              this.ykypromotionList = res.data;
              this.ykyactivityTotal = res.changes;
              let now = new Date().getTime();
              this.ykypromotionList.forEach((value) => {
                if (
                  now >= new Date(value.end_at).getTime() &&
                  value.category == "促销"
                ) {
                  value.end = true;
                } else {
                  value.end = false;
                }
                if (new Date(value.end_at).getTime() < now) {
                  value.isend = true;
                } else {
                  value.isend = false;
                }
                if (
                  new Date(value.end_at).getTime() - now <=
                  value.end_duration * 60 * 1000
                ) {
                  value.ednTips = true;
                } else {
                  value.ednTips = false;
                }
                // console.log(this.isshowaActivePop, "this.isshowaActivePop");
                if (
                  value._id == "666a5ecf541e8cd318956b0d" &&
                  !this.isshowaActivePop
                ) {
                  this.showText = true;
                  this.popActive = value;
                }
              });

              // let arr = this.ykypromotionList.filter(
              //   (item) => item.name == "11月累计提货获得秒杀机会"
              // );
              // this.msItem = arr[0] ? arr[0] : {};
            }
          } else {
            // this.$toast(res.errmsg);
          }
        })
        .catch((err) => {});
    },
    async getykyAllPromotionList() {
      https.get(`/promotion/app/activity`).then((res) => {
        if (res.data) {
          this.ykyisPosterList = res.data.filter((el) => {
            el.activityShow = true;
            return el.is_poster;
          });
          if (this.ykyisPosterList.length > 0) {
            this.ykyisPosterList.forEach((v) => {
              if (v.id === localStorage.getItem("activityId"))
                v.activityShow = false;
            });
          }
          this.ykyallPromotionList = res.data;
          if (!this.$store.state.shopCartCount) {
            this.ykyallPromotionList.map(async (item) => {
              item.carCount = await this.getcart(item._id);
              return item;
            });
            setTimeout(() => {
              let obj = this.ykyallPromotionList.find(
                (item) => item.carCount > 0
              );
              if (obj) {
                this.$store.commit("getShopCartCount", true);
              } else {
                this.$store.commit("getShopCartCount", false);
              }
            }, 100);
          }
        }
      });
    },
    async getcart(id) {
      let res = await https.get("promotion/app/cart/" + id);
      let count = 0;
      if (res.data.goods) {
        res.data.goods.forEach((item) => {
          let inum = 0;
          item.sku_list.forEach((e) => {
            inum += e.count;
          });
          count += inum;
        });
      }
      return count;
    },
    // 获取所有活动列表
    getAllPromotionList() {
      http.get(`/promotion/active`).then((res) => {
        if (res.ok && res.data) {
          this.isPosterList = res.data.filter((el) => {
            el.activityShow = true;
            return el.is_poster;
          });
          let formshowlist = res.data.filter((el) => {
            return el.explain == "1月累计提货获得秒杀机会";
          });
          this.isformshow = formshowlist.length > 0 ? true : false;
          if (this.isPosterList.length > 0) {
            this.isPosterList.forEach((v) => {
              if (v.id === localStorage.getItem("activityId"))
                v.activityShow = false;
            });
          }
          this.allPromotionList = res.data;
        }
      });
    },
    // 获取活动列表
    getPromotionList(size) {
      let pg = size ? `?skip=0&limit=${size}` : "";
      http
        .get(`/promotion/active${pg}`)
        .then((res) => {
          if (res.ok) {
            if (res.data) {
              this.promotionList = res.data;
              this.activityTotal = res.changes;
              let now = new Date().getTime();
              this.promotionList.forEach((value) => {
                if (
                  now >= new Date(value.stoptime).getTime() &&
                  value.category == "促销"
                ) {
                  value.end = true;
                } else {
                  value.end = false;
                }
                if (new Date(value.stoptime).getTime() < now) {
                  value.isend = true;
                } else {
                  value.isend = false;
                }
                if (
                  new Date(value.stoptime).getTime() - now <=
                  value.end_duration * 60 * 1000
                ) {
                  value.ednTips = true;
                } else {
                  value.ednTips = false;
                }
              });

              let arr = this.promotionList.filter(
                (item) => item.name == "11月累计提货获得秒杀机会"
              );
              this.msItem = arr[0] ? arr[0] : {};
            }
          } else {
            // this.$toast(res.errmsg);
          }
        })
        .catch((err) => {});
    },
    // 获取更多活动
    getActivityMore() {
      this.getMoreNum = 1;
      sessionStorage.setItem("getMore", "1");
      this.getPromotionList();
    },
    getykyActivityMore() {
      this.getMoreNum = 1;
      sessionStorage.setItem("getMore", "1");
      this.getykyPromotionList();
    },
    formatDateTime() {
      const time = new Date();
      let h = time.getHours();
      let m = time.getMinutes();
      time.getSeconds();
      let hh = h * 60 * 60;
      let mm = m * 60;
      let ss = Number(hh) + Number(mm) + time.getSeconds();
      return ss;
    },
    topro(item) {
      var t;
      if (item.day_type == "weekday") {
        t = new Date().getDay();
      } else if (item.day_type == "month") {
        t = new Date().getDate();
      }
      if (!item.days.includes(t)) {
        if (item.time_remind == "") {
          return;
        }
        this.$toast(item.time_remind);
        return;
      }

      let tt = this.formatDateTime();
      if (item.time_start > tt || tt > item.time_end) {
        if (item.time_remind == "") {
          return;
        }
        this.$toast(item.time_remind);
        return;
      }
      var moneyType = "";
      if (item.moneyType != undefined) {
        moneyType = item.moneyType;
      }
      sessionStorage.setItem("enterremind", item.enterremind);
      this.$router.push({
        path: "/promotion/" + item.id,
        query: {
          id: item.id,
          isenterremind: item.isenterremind ? "1" : "0",
          moneytype: moneyType,
        },
      });
      sessionStorage.setItem("category_freights", item.category_freights);
      sessionStorage.setItem("is_real_name", item.is_real_name ? "1" : "0");
    },
    toykypro(item, name) {
      if (name == "single") {
        sessionStorage.setItem("isshowaActivePop", true);
      }
      let tt = this.formatDateTime();
      sessionStorage.setItem("pay_success_explain", item.pay_success_explain);
      if (item.start_at > tt || tt > item.end_at) {
        if (item.time_remind == "") {
          return;
        }
        this.$toast(item.time_remind);
        return;
      }
      // sessionStorage.setItem("enterremind", item.enterremind);
      this.$router.push({
        // path: "/ykypromotion/" + item._id,
        path: "/activelist/" + item._id,
        query: {
          id: item._id,
          isenterremind: item.alert_explain ? "1" : "0",
          moneytype:
            item.money_type && item.money_type.length ? item.money_type : [],
          pay_mode: item.pay_mode && item.pay_mode.length ? item.pay_mode : [],
          is_pass: item.is_pass,
          max_money: item.max_money,
          max_pay: item.max_pay,
          min_money: item.min_money,
          min_pay: item.min_pay,
        },
      });
      sessionStorage.setItem("mode", item.mode);
      // sessionStorage.setItem("is_real_name", item.is_real_name ? "1" : "0");
    },
    // 复制订单编号
    copy(index, item, type) {
      let url =
        "https://dly.caredaily.com/#/static?id=" +
        (type ? item._id : item.id) +
        "&invite=" +
        sessionStorage.getItem("oid") +
        "&type=" +
        type;
      // let url = 'https://test.hkaspire.net/#/static?id='+item.id+'&invite='+sessionStorage.getItem('oid')
      http.copy(this.copy_bt + type, index, url);
    },
    // 系统消息列表
    goNotice() {
      let systemList = [];
      https.get("/article/app/getnoticeclassify").then((res) => {
        if (res.data) {
          for (var i = res.data.length - 1; i >= 0; i--) {
            systemList.push(res.data[i]);
          }
          if (
            (this.agentsMsg.follow && this.agentsMsg.follow.level == 200) ||
            this.agentsMsg.level == 10
          ) {
            this.systemList = systemList;
          } else {
            this.systemList = systemList.filter(
              (item) => item.name != "稽查通知" && item.name != "专营店通知"
            );
          }
        } else {
          this.$toast(res.message);
        }
      });
    },

    goMessageList(item) {
      sessionStorage.removeItem("scrollTop");
      this.$router.push({
        name: "messagelist",
        query: {
          id: item._id,
        },
      });
    },
    // 赠送弹窗
    give(item) {
      if (item.isGive) {
        this.$refs.mychild.popup(item);
        this.backtopshow = false;
      }
    },
  },
  computed: {
    // 退出退款账号 每月1-5号、16-20号不能修改，不能新增
    limitTime() {
      var d = new Date();
      let startDay = new Date(d.getFullYear(), d.getMonth(), 1);
      let endDay = new Date(d.getFullYear(), d.getMonth(), 16);
      let st = startDay.getTime() + 1000 * 60 * 60 * 24 * 5;
      let en = endDay.getTime() + 1000 * 60 * 60 * 24 * 5;
      if (
        (d.getTime() <= st && d.getTime() >= startDay.getTime()) ||
        (d.getTime() <= en && d.getTime() >= endDay.getTime())
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/modifyvant.css";
.flex {
  display: flex;
}
.just-between {
  justify-content: space-between;
}
.allscroll {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: #f5f6fa;
}

.msg {
  z-index: 10;
  width: 100%;
  padding: 20px 13px 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;

  .msg-left {
    // width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    .levels_group {
    }
    .hp {
      width: 35px;
      height: 35px;
      background-color: #e4e5e7;
      border-radius: 50%;
      margin-right: 5px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    .agsname {
      // width: 50px; height: 17px;
      font-size: 12px;
      font-family: PingFangHK-Medium, PingFangHK;
      font-weight: 600;
      color: rgba(0, 0, 0, 1);
      line-height: 17px;
    }
  }

  .msg-right-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .msg-center {
      margin-right: 8px;

      .kefus {
        position: relative;

        .red {
          position: absolute;
          display: block;
          width: 5px;
          height: 5px;
          background: #ff5858;
          border-radius: 50%;
          top: 2px;
          right: 4px;
        }
      }
    }
  }

  .msg-right {
    width: 66px;
    height: 26px;
    background: #f5f6fa;
    border-radius: 13px;
    position: relative;
    text-align: center;
    line-height: 26px;
    display: flex;
    align-items: center;

    .kefus {
      width: 22px;
      height: 22px;
      padding: 2px;
      margin-left: 10px;
      margin-right: 1px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .span2 {
      display: block;
      font-size: 11px;
      font-family: PingFangHK-Regular, PingFangHK;
      font-weight: 400;
      color: rgba(0, 0, 0, 1);
      -webkit-transform-origin-x: 0;
      -webkit-transform: scale(0.92);
    }
  }
}

.Shuffling {
  width: 100%;
  height: 200px;
  background-image: linear-gradient(#fff, #f7f9fc);
  text-align: center;
  padding: 8px 0;

  .van-swipe {
    margin: 0 auto;
    width: 343px;
    height: 100%;
    border-radius: 5px;

    img {
      width: 100%;
    }
  }
}

.nav {
  width: 100%;
  height: 80px;
  margin-top: 6px;
  padding: 5px 5px;
  display: flex;
  justify-content: space-around;
}

.searchBar {
  .isFixed {
    position: fixed;
    background-color: #fff;
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 80px;
    padding: 5px 5px;
  }
}

.nav-list {
  text-align: center;

  .list-img {
    margin: 0 auto;
    width: 48px;
    height: 54px;
  }

  span {
    margin: 0 auto;
    display: block;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 1);
    -webkit-transform: scale(0.84);
  }
}

.nav-lista {
  .list-img {
    background: url("../../cmmon/image/index1/dibu/活动中心@2x.png") no-repeat;
    background-size: contain;
  }
}

.nav-listb {
  .list-img {
    background: url("../../cmmon/image/index1/dibu/政策解读@2x.png") no-repeat;
    background-size: contain;
  }
}

.nav-listc {
  .list-img {
    background: url("../../cmmon/image/dy/抖音服务@2x.png") no-repeat;
    background-size: contain;
  }
}

.nav-listd {
  .list-img {
    background: url("../../cmmon/image/index1/dibu/产品大全@2x.png") no-repeat;
    background-size: contain;
  }
}

.nav-liste {
  .list-img {
    background: url("../../cmmon/image/index1/dibu/系统消息@2x.png") no-repeat;
    background-size: contain;
    position: relative;

    em {
      font-size: 12px;
      display: inline-block;
      position: absolute;
      width: 25px;
      height: 17px;
      text-align: center;
      line-height: 17px;
      background-color: #ff6060;
      border-radius: 9px;
      color: #fff;
      top: -5px;
      right: -5px;
    }
  }
}

.nav-listf {
  .list-img {
    background: url("../../cmmon/image/index1/dibu/换购活动@2x.png") no-repeat;
    background-size: contain;
  }
}

.information {
  width: 100%;
  height: 40px;
  padding: 0 16px;
  box-sizing: border-box;
  margin-bottom: 15px;
  margin-top: 10px;

  .contain {
    width: 343px;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    overflow: hidden;
  }
}

.zwz {
  width: 100%;
  height: 55px;
}

.activity_center {
  font-size: 14px;
  padding: 0 15px;

  .activity_content {
    h3 {
      line-height: 52px;
      font-size: 18px;
    }

    .activity_info {
      margin-bottom: 12px;

      .activity_img {
        position: relative;

        img {
          width: 100%;
          height: 163px;
          border-radius: 10px 10px 0 0;
        }

        .date_icon {
          position: absolute;
          top: 14px;
          left: 16px;
          display: block;
          height: 21px;
          line-height: 21px;
          background: #ff4446;
          color: #fff;
          font-size: 12px;
          border-radius: 4px;
          padding: 0 8px 0 17px;

          &::before {
            display: block;
            content: "";
            width: 5px;
            height: 5px;
            background: #fff;
            border-radius: 50%;
            position: absolute;
            left: 8px;
            top: 8px;
          }
        }

        .link_icon {
          position: absolute;
          bottom: 14px;
          right: 16px;
          display: block;
          height: 21px;
          line-height: 21px;
          background: #fff;
          color: #222;
          font-size: 12px;
          border-radius: 10px;
          padding: 0 10px;
        }

        span {
          position: absolute;
          bottom: 14px;
          right: 16px;
          display: block;
          height: 21px;
          line-height: 21px;
          background: #fff;
          color: #222;
          font-size: 12px;
          border-radius: 10px;
          padding: 0 10px;
        }

        .none {
          display: none;
        }
      }

      .activity_context {
        background: #fff;
        padding: 12px 16px 0;
        border-radius: 0 0 10px 10px;

        h4 {
          line-height: 22px;
          font-size: 16px;
          margin-bottom: 4px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          word-break: break-all;
        }

        .buy_num {
          display: inline-block;
          position: relative;
          padding: 0 14px 0 25px;
          height: 18px;
          font-size: 12px;
          line-height: 18px;
          color: #ff4446;
          background: rgba(255, 68, 70, 0.12);
          border-radius: 30px;

          i {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 18px;
            height: 18px;
            background: #ccc;
            border-radius: 50%;
            background: url("../../cmmon/image/index1/dibu/buyNum.png")
              no-repeat;
            background-size: contain;
          }

          b {
            font-weight: bold;
            margin-left: 4px;
          }
        }

        .give {
          display: inline-block;
          color: #ff4446;
          font-size: 12px;
          border: 1px solid #ff4446;
          background: #fff;
          padding: 0 5px;
          height: 16px;
          text-align: center;
          line-height: 14px;
          border-radius: 10px;
          margin-left: -8px;
          position: relative;
          z-index: 2;
        }

        .date_bottom {
          display: flex;
          padding: 18px 0;
          justify-content: space-between;
          align-items: center;
          color: #666666;

          .date {
            line-height: 18px;
          }

          .btn {
            position: relative;
            background: #4380f1;
            border-radius: 20px;

            .type_icon {
              position: absolute;
              display: block;
              top: -9px;
              right: 0;
              width: 60px;
              height: 28px;
              background: #4380f1;
              color: #fff;
              text-align: center;
              line-height: 18px;
              font-size: 11px;
              border-radius: 9px 9px 0 9px;
              background: url("../../cmmon/image/index1/dibu/label.png")
                no-repeat;
              background-size: contain;
            }

            span {
              display: block;
              width: 91px;
              height: 38px;
              background: rgba(67, 128, 241, 0.2);
              border: 1px solid #4380f1;
              border-radius: 20px 20px 20px 20px;
              text-align: center;
              line-height: 38px;
              color: #4380f1;
              z-index: 10;
              background: #dae6fd;
            }
          }
        }
      }
    }

    .system_info {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 86px;
      padding: 0 17px 0 7px;
      background: #fff;
      border-radius: 12px;

      .system_icon {
        width: 76px;
        height: 76px;
        margin-right: 16px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .msg_info {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        .msg_text {
          flex: 1;
        }

        h4 {
          font-size: 16px;
          color: #222222;
          line-height: 22px;
        }

        p {
          color: #666666;
          font-size: 14px;
          line-height: 20px;
          margin-top: 4px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          word-break: break-all;
        }

        .right_arrow {
          margin-left: 32px;
          width: 13px;
          height: 13px;
          background: url("../../cmmon/image/index1/dibu/jt备份@2x.png")
            no-repeat;
          background-size: contain;
        }
      }
    }
  }
}
.get_more {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: inline-block;
    width: 90px;
    height: 26px;
    text-align: center;
    line-height: 26px;
    background: #fff;
    color: #4380f1;
    font-size: 12px;
    border-radius: 15px;
    position: relative;

    &::before {
      display: block;
      content: "";
      width: 13px;
      height: 13px;
      background: url("../../cmmon/image/index1/dibu/jt@2x.png") no-repeat;
      background-size: contain;
      position: absolute;
      right: 8px;
      bottom: 6px;
    }
  }
}
.van-popup--center {
  border-radius: 10px;
}

.showkuc {
  width: 100%;
  max-height: 500px;
  padding: 20px 10px;
  overflow-y: scroll;
  text-align: center;
}

.unreadmsg {
  .msgtips {
    margin: 0 auto;
    -moz-box-shadow: 2px 2px 10px #bfbfbf;
    -webkit-box-shadow: 2px 2px 10px #bfbfbf;
    box-shadow: 2px 2px 20px #bfbfbf;
    width: 50px;
    height: 50px;
    border-radius: 50px;

    img {
      width: 100%;
    }
  }

  p {
    font-size: 14px;
    margin: 10px 0;
  }

  ul {
    overflow-y: scroll;
    max-height: 400px;

    li {
      padding: 0 10px 0 20px;
      margin-bottom: 25px;

      .times {
        font-size: 12px;
        display: block;
        margin: 0 auto;
        text-align: left;
      }

      .msg_text {
        background-color: #fff;
        border-radius: 10px;
        text-align: left;
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        position: relative;

        .symbol {
          position: absolute;
          left: -15px;
          top: 8px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #4380f1;
        }
      }
    }
  }
}

.showtan {
  padding: 1em;
  text-align: center;
  .closeicon {
    position: absolute;
    right: 10px;
    top: 15px;
  }
  .hx_title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 10px;
    color: rgba(243, 24, 35, 0.8);
  }
  .con_title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 10px;
    color: rgba(250, 81, 160, 0.8);
  }
  .con_center {
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0;
  }
  .hx_center {
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0;
  }

  div {
    line-height: 30px;
  }

  .title {
    font-size: 18px;
    color: #222;
    text-align: center;
    position: relative;
    width: 100%;

    span {
      color: red;
      // position: absolute; // top: 0; // right: 0; // img { //   width: 20px; //   height: 20px; // }
    }
  }

  .img_con {
    width: 60%;
    text-align: center;
    margin: 0 auto;
    margin-top: 45px;
    margin-bottom: 45px;

    img {
      width: 100%;
    }
  }

  .show_btn {
    display: flex;
    justify-content: space-between;

    .van-button {
      width: 45%;
      border-radius: 20px;
      background-color: white;
      border: 1px solid #4380f1;
      color: #4380f1;
    }

    .file_updates {
      width: 45%;
      position: relative;

      .file_update {
        // width: 100px; // margin-left: -50px; position: absolute;
        z-index: 999;
        margin-top: 10px;
        opacity: 0;
      }

      .update {
        width: 100%;
        background-color: #4380f1;
        color: white;
      }
    }
  }

  .titles {
    font-weight: bold;
  }

  .show_btns {
    margin-top: 20px;

    .van-button {
      width: 60%;
      border-radius: 20px;
      background-color: #4380f1;
      color: white;
    }
  }
  .con_btns {
    margin-top: 30px;
    &.hx_btn {
      .van-button {
        width: 40%;
        height: 35px;
        border-radius: 20px;
        background-color: rgba(15, 73, 100, 0.979);
      }
    }
    .van-button {
      width: 50%;
      height: 40px;
      border-radius: 20px;
      background-color: rgba(250, 81, 160, 0.5);
      color: white;
    }
  }
}

// .btnss{
//   width: 50px;
//   height: 50px;
//   background-color: blueviolet;
//   font-size: 12px;
//   position: fixed;
//   bottom: 50px;
//   right: 50px;
// }
.show_lim {
  .titles {
    font-weight: 500;
  }

  .title {
    font-size: 14px;
  }

  .show_btnslim {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;

    .van-button {
      width: 45%;
    }
  }
}

.prize-banner {
  padding: 12px 16px;

  .prize-img {
    width: 100%;
    height: 80px;

    img {
      width: 100%;
      height: 80px;
      border-radius: 10px;
    }
  }
}

.dialog-img {
  width: 284px;
  height: 400px;
  border-radius: 11px;
  background: transparent !important;

  /deep/ .van-dialog__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .showtans {
      width: 100%;
      padding: 0;
      position: relative;

      img {
        width: 100%;
        height: 370px;
        border-radius: 11px;
      }

      .btns {
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 137px;
        height: 44px;
        border-radius: 24px;
        background: #ba8467;
        font-size: 18px;
        color: #f9f9f9;
      }
    }

    .icon_close {
      display: block;
      width: 24px;
      height: 24px;
      background: url("../../cmmon/image/prize/按钮@2x.png") no-repeat;
      background-size: contain;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 10001;
    }
  }
}

.dialog-img2 {
  width: 375px;
  height: 418px;
  border-radius: 11px;
  background: transparent !important;

  /deep/ .van-dialog__content {
    .showcoupon {
      position: relative;
      .popup_con {
        padding: 126px 75px 60px 75px;
        width: 375px;
        height: 368px;
        background: url("../../cmmon/image/ms_img@2x.png") no-repeat;
        background-size: 100% 100%;
      }
      .num {
        margin-bottom: 10px;
        .label {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #222222;
          line-height: 19px;
        }
        .value {
          font-size: 16px;
          font-family: OPPOSans-H, OPPOSans;
          font-weight: normal;
          color: #ff3f3e;
          line-height: 25px;
        }
      }

      .icon_close {
        width: 30px;
        height: 30px;
        margin-bottom: 20px;
        margin-left: 80%;
      }
      .complated {
        position: absolute;
        top: 254px;
        left: 96px;
        img {
          height: 21px !important;
          width: 69px !important;
        }
      }

      img {
        width: 375px;
        height: 368px;
      }

      .btns {
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 137px;
        height: 44px;
        border-radius: 24px;
        background: #ba8467;
        font-size: 18px;
        color: #f9f9f9;
      }
    }
  }
}

.dialog-imgs {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  .icon_close {
    display: block;
    width: 24px;
    height: 24px;
    background: url("../../cmmon/image/cha@2x.png") no-repeat;
    background-size: contain;

    // position: absolute; // top: 0; // left: 50%; // transform: translate(-50%, 0); // z-index: 10001;
  }
}

.limiting {
  padding: 0 0.4rem;

  .limiting_con {
    padding: 0.5em;
    background-color: white;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;

    .limiting_left {
      font-size: 15px;
      display: flex;

      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    .limiting_right {
      font-size: 12px;

      span {
        padding: 6px 10px;
        border-radius: 10px;
        border: #4380f1 1px solid;
        background-color: rgba(67, 128, 241, 0.2);
        color: #4380f1;
      }
    }
  }
}
.ykyactive {
  background: #f5e9db;
  padding: 12px 20px 16px;
  margin-bottom: 20px;
  .label_box {
    display: flex;
    .triangle-left {
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-right: 10px solid #f5e9db;
      border-bottom: 12px solid transparent;
      margin-left: -10px;
    }
    .triangle_box {
      width: 10px;
      height: 24px;
      background-color: #f5e9db;
    }
  }
  .label {
    width: 110px;
    height: 24px;
    line-height: 24px;
    margin-left: -26px;
    padding-left: 12px;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    background-color: #402510;
  }
  .banner {
    height: 148px;
    margin: 16px 0;
    width: 100%;
  }
  .classname {
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #222222;
    line-height: 22px;
    margin-bottom: 12px;
  }
  .btn {
    width: 100%;
    background: #402510;
    border-radius: 19px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }
}
.copytext {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #fff;
  border: 1px solid #f5f5f5;
  padding: 2px 8px;
  border-radius: 24px;
}
.popbox {
  .icon_close {
    width: 30px;
    height: 30px;
    margin-bottom: 20px;
    margin-left: 80%;
  }
}
.imgwarp {
  width: 100%;
  height: 401px;
  position: relative;
  text-align: center;
  padding-top: 128px;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 401px;
    z-index: -1;
  }
  .wzj {
    .lable_item {
      margin: 5px 0;
    }
  }
  .lable_item {
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #a13f06;
    line-height: 22px;
  }
  .num_item {
    font-size: 32px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #fe0404;
    line-height: 45px;
    margin: 5px 0;
  }
  .bottomtips {
    font-size: 10px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
    position: absolute;
    bottom: 30px;
    padding: 0 30px 0 40px;
    display: flex;
    transform: scale(0.9);
    .tipslable {
      min-width: 58px;
      text-align: right;
    }
  }
}
.nhzj {
  position: fixed;
  bottom: 200px;
  right: 0;
  width: 65px;
  height: 70px;
  &.egg {
    width: 65px;
    height: 83px;
    // right: -7px;
  }
}
.move-box {
  animation: moveright 1s;
  animation-fill-mode: both;
}
@keyframes moveright {
  0% {
    right: 0;
  }
  100% {
    right: -67px;
  }
}
.move-box2 {
  animation: moveleft 1s;
}
@keyframes moveleft {
  0% {
    right: -67px;
  }
  100% {
    right: 0px;
  }
}
.contract {
  height: 100%;
  padding: 40px 16px 60px 16px;
  font-size: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  .contract_title {
    text-align: center;
    margin-bottom: 20px;
    height: fit-content;
  }
  .content {
    flex: 1;
    overflow-y: scroll;
  }
  .contract_btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 12px;
    left: 0;
    padding: 0px 40px;
    .van-button {
      width: 100px !important;
      height: 40px;
    }
  }
}
.levels_icon {
  height: 18px;
  margin-top: 5px;
}
</style>
