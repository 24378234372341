<!--
 * @Author: your name
 * @Date: 2019-11-17 23:32:12
 * @LastEditTime : 2019-12-26 01:42:35
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \caredily66\src\components\common\tabbar.vue
 -->
<template>
  <div>
    <authorizedPhone></authorizedPhone>
    <div class="footer">
      <van-tabbar
        v-model="active"
        class="active_tab"
        active-color="#FF5F00"
        inactive-color="#999999"
      >
        <van-tabbar-item
          v-for="(item, index) in tabbars"
          :key="index"
          :badge=" item.title === '我的' ? total : null"
          :dot="item.title === '购物车'? $store.state.shopCartCount?true: false:false"
          @click="tab(index, item.name)"
        >
          <span :class="currIndex == index ? 'active' : ''">{{ item.title}}</span>
          <template slot="icon" slot-scope="props">
            <img :src="props.active ? item.active : item.normal" />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import authorizedPhone from "../../views/common/authorizedPhone.vue";
import http from "../../cmmon/js/http.js";
import https from "../../cmmon/js/https.js";
export default {
  name: "tabber",
  components:{authorizedPhone},
  data() {
    return {
      active: 0,
      currIndex: 0,
      show: "",
      isShow: "",
      icon: {
        active: "/src/cmmon/image/tabbar/newicon/home.png",
        inactive: "/src/cmmon/image/tabbar/newicon/home_cur.png",
      },
      total: null,
      shopCartCount: 0,
      tabbars: [
        {
          name: "index",
          title: "首页",
          normal: require("../../cmmon/image/tabbar/newicon/home.png"),
          active: require("../../cmmon/image/tabbar/newicon/home_cur.png"),
          info: "",
        },
        {
          name: "newproduct",
          title: "下单",
          normal: require("../../cmmon/image/tabbar/newicon/place.png"),
          active: require("../../cmmon/image/tabbar/newicon/place_cur.png"),
          info: "",
        },
        // {
        //   name: "cart",
        //   title: "博乐商学院",
        //   normal: require("../../cmmon/image/tabbar/college.png"),
        //   active: require("../../cmmon/image/tabbar/college_cur.png")
        // },
        {
          // name: `${this.$store.state.shopCartCount != 0 ? 'shopCart' : 'emptyPage'}`,
          name: "",
          title: "购物车",
          normal: require("../../cmmon/image/tabbar/newicon/购物车2@2x.png"),
          active: require("../../cmmon/image/tabbar/newicon/购物车@2x.png"),
          info: this.$store.state.shopCartCount,
        },
        {
          name: "me",
          title: "我的",
          normal: require("../../cmmon/image/tabbar/newicon/mine.png"),
          active: require("../../cmmon/image/tabbar/newicon/mine_cur.png"),
          info: null,
        },
      ],
      Promotioninfo: {},
    };
  },
  mounted() {
    this.show = sessionStorage.getItem("agentIsShow");
    // // 判断当前页面是否为home或newpersonal页面
    const CURRENTTABBAR = window.location.hash.split("/")[1];
    // console.log(CURRENTTABBAR,'CURRENTTABBAR')

    if (CURRENTTABBAR === "index") {
      this.active = 0;
    } else if (CURRENTTABBAR.includes("newproduct")) {
      this.active = 1;
    } else if (CURRENTTABBAR.includes("shopCart")) {
      this.active = 2;
    } else if (CURRENTTABBAR.includes("me")) {
      this.active = 3;
    }
    this.getUndeaL();
  },
  created() {
    this.isShow = this.$store.state.isShow;
    this.getykyAllPromotionList();
  },
  computed: {
    orderCount() {
      return this.$store.state.orderCount;
    },
  },
  methods: {
    getykyAllPromotionList() {
      https.get(`/promotion/app/activity?category_page=true`).then((res) => {
        if (res.data.length>0) {
          this.Promotioninfo = res.data[0];
        }
      });
    },
    tab(index, val) {
      // this.active = index
      this.currIndex = index;
      // console.log(index,this.active,this.currIndex,'index')

      if (index === 1) {
        this.$router.push({
          path: "/newproduct",
          query: {
            type: 3,
            activity_id: this.Promotioninfo._id?this.Promotioninfo._id:'',
          },
        });
      }else if (index === 2) {
        this.$router.push({
          path: "/newshopCart",
          query: {
            activity_id: this.Promotioninfo._id,
          },
        });
      } else {
        this.$router.push(val);
      }

    },
    // 请求待处理数据条数
    async getUndeaL() {
      try {
        let res = await https.get("message/app/undeal");
        if (res.code == 0) {
          if (!res.data.sign_history) {
            sessionStorage.setItem("signhistory", 0);
          } else {
            sessionStorage.setItem("signhistory", res.data.sign_history);
          }
          for (var key in res.data) {
            if (key !== "特种兵发货" && key !== "sign_history" && key !== 'sign_history_partner') {
              this.total += res.data[key];
            }
          }
          this.total = this.total != 0 ? this.total : null;
        }
      } catch (e) {
        this.$toast(e.toString());
      }
    }
  },
};
</script>
<style lang="less" scoped>
.footer {
  width: 100%;
  height: 48px;
  background-color: #fff;
  display: flex;
  position: fixed;
  justify-content: space-around;
  left: 0;
  bottom: 0;
  z-index: 2;
  padding: 2px;
  .tab {
    text-align: center;
    flex: 1;
    color: #000000;
    img {
      margin: 0 auto;
      width: 26px;
      height: 26px;
    }
    p {
      font-size: 12px;
      font-weight: 500;
    }
    &.active {
      color: #FF5F00;
    }
  }
  .van-tabbar-item__icon {
    img {
      width: 26px;
      height: 26px;
    }
  }
}
/deep/.van-info {
  background-color: #FF5F00 !important;
}
</style>
