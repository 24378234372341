<template>
  <!-- 弹窗 -->
  <div>
    <van-dialog class="dialog-img dialog-img-milk" v-model="isNotifitionTrue" :show-confirm-button="false"
                :show-cancel-button="false">
      <van-swipe class="my-swipe" indicator-color="black">
        <van-swipe-item v-for="(Popup, index) in isNotifitionArr" :key="index">
          <div class="showtans" @click="goDetl(Popup)">
            <img :src="Popup.Popup"/>
          </div>
        </van-swipe-item>
      </van-swipe>
      <i class="icon_close" @click="closeMilkPopup"></i>
    </van-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isNotifitionTrue: {
      typeof: Boolean,
      default: false
    },
    isNotifitionArr: {
      typeof: Array,
    },
    isLink: {
      typeof: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      // indexPopup: [
      //     {
      //         Popup: 'https://img01.yzcdn.cn/vant/apple-3.jpg'
      //     },
      //     {
      //         Popup: 'https://img01.yzcdn.cn/vant/apple-3.jpg'
      //     },
      //     {
      //         Popup: 'https://img01.yzcdn.cn/vant/apple-3.jpg'
      //     }
      // ]
    }
  },
  created() {
  },
  methods: {
    goDetl(item) {
      if(this.isLink) return
      location.href = item.jump_link
      setTimeOut(() => {
        location.reload(true)
      }, 1000)
    },
    closeMilkPopup() {
      this.$emit('set_isNotifitionTrue', false)
    },
  }
}
</script>

<style lang="less" scoped>
.dialog-img {
  width: 284px;
  height: 400px;
  border-radius: 11px;
  background: transparent !important;

  /deep/ .van-dialog__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .showtans {
      width: 100%;
      padding: 0;
      position: relative;

      img {
        width: 100%;
        height: 370px;
        // border-radius: 11px;
      }

      .btns {
        position: absolute;
        bottom: 24px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 137px;
        height: 44px;
        border-radius: 24px;
        background: #ba8467;
        font-size: 18px;
        color: #f9f9f9;
      }
    }

    .icon_close {
      display: block;
      width: 24px;
      height: 24px;
      background: url("../cmmon/image/prize/按钮@2x.png") no-repeat;
      background-size: contain;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 10001;
    }
  }
}
</style>
