<!--
 * @Author: your name
 * @Date: 2019-11-11 16:10:25
 * @LastEditTime: 2019-11-11 16:30:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \caredily66\src\App.vue
 -->
<template>
  <div id="app">
    <!-- v-if="$route.path!='/login' || $route.path!='/system' || $route.path!='/'" -->
    <!-- <popups ref="mypopups"  v-if="$route.path!='/login' && $route.path!='/system' && $route.path!='/' &&  $route.path!='/registered'"></popups> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- <notification :isNotifitionArr="isNotifitionArr" :isNotifitionTrue="isNotifitionTrue"
      @set_isNotifitionTrue="get_isNotifitionTrue"></notification> -->
  </div>
</template>

<script>
// import popups from "./views/index/common/MS_popup.vue";
import { mapState } from 'vuex';
import Loading from '../../caredaily_mobile_frontend_v2/src/views/error/loading.vue';
import https from "./cmmon/js/https";
import notification from "./components/notification.vue";
export default {
  components: {
    // notification
    // popups,
  },
  name: "App",
  data() {
    return {
      isNotifitionTrue: false,
      isNotifitionArr: [],
      remainingSeconds: 10,
      startTime: null,
      elapsedTime: 0
    }
  },
  created() {
    // this.getDetails()
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#F1F3F9");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  methods: {
    // async getDetails() {
    //   let res = await https.get("/article/app/get_popup?popup_type=浏览系统时长弹窗");
    //   if (res.data.length == 0) {
    //     return
    //   }
    //   if (res.data) {
    //     this.isNotifitionTrue = true
    //     this.isNotifitionArr = res.data.map(item => {
    //       let data = {
    //         Popup: item.popup_placard,
    //         jump_link: item.jump_link
    //       }
    //       this.startTime = new Date();
    //       this.browseTime = item.brows_time
    //       this.startTrackingTime();
    //       return data
    //     })
    //   }
    // },
    // startTrackingTime() {
    //   setInterval(() => {
    //     const currentTime = new Date();
    //     this.elapsedTime = Math.floor((currentTime - this.startTime) / 1000);
    //     this.formatDuration();
    //     if (this.elapsedTime === this.browseTime) {
    //       this.specialFunction();
    //     }
    //   }, 1000);
    // },
    // formatDuration() {
    //   const minutes = Math.floor(this.elapsedTime / 60);
    //   const seconds = this.elapsedTime % 60;
    //   this.formattedDuration = `${minutes} 分 ${seconds} 秒`;
    // },
    // specialFunction() {
    //   this.isNotifitionTrue = true
    //   console.log('浏览时长达到 60 秒，调用此函数');
    //   this.getDetails()
    // },
    // get_isNotifitionTrue(state) {
    //   this.isNotifitionTrue = state
    // },
  },

};
</script>

<style lang="less">
body {
  color: #333;
}

// 动态表单样式
.platform-w {
  padding: 0 .4rem .4rem .3rem;
  height: 1.4rem;
  line-height: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .right-w {
    display: flex;
    align-items: center;

    span {
      font-size: 12px;
      display: inline-block;
      margin-right: 0.24rem;
      color: #999;
    }

    .icon-right-w {
      display: inline-block;
      width: 14px;
      height: 14px;
      background: url("./cmmon/image/gRight.png") no-repeat;
      background-size: contain;
    }
  }
}

.accredit-w {
  color: #333;
  flex: 1;
  font: bold 0.32rem PingFangSC-Medium;
}

.textstring-w {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 0 0.4rem 0.4rem 0.3rem;
  border-radius: 12px;

  input {
    font-size: 12px;
    border-radius: 5px;
  }
}


.chooseAddress-w {
  width: 100%;

  /deep/.picker-items {
    font-size: 12px;
  }

  p {
    border-bottom: 1px solid #ececec;
    padding-left: 10px;
    font: bold 16px/45px PingFangSC-Medium;
  }

  .addressBox-w {
    padding: 5px;
    display: flex;
  }
}

.fujian-w {
  width: 100%;
  background-color: #fff;
  margin-bottom: 10px;
  border-radius: 12px;

  .title-w {
    padding: 0.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
    -webkit-tap-highlight-color: transparent;

    .right-w {
      width: 70px;
      height: 36px;
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow: hidden;

      .filebox-w {
        display: inline-block;
        width: 15px;
        height: 15px;
        background: url("../src/cmmon/image/jia.png") no-repeat center;

        input {
          width: 50px;
          height: 50px;
          opacity: 0;
        }
      }

      span {
        font-size: 12px;
        display: inline-block;
        color: #999;
      }
    }
  }

  ul {
    width: 100%;
    font-size: 0.28rem;
    color: #999;

    li {
      height: 1rem;
      line-height: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .name-w {
        height: 1rem;
        display: flex;
        flex: 1;
        align-items: center;
        padding-left: 0.4rem;

        em {
          display: inline-block;
          width: 4px;
          height: 4px;
          margin-right: 0.2rem;
          background-color: royalblue;
          border-radius: 50%;
        }

        span {
          display: inline-block;
          width: 5rem;
        }
      }

      .remove-w {
        width: 0.44rem;
        display: flex;
        align-items: center;
        margin-right: 0.4rem;

        .icon-remove-w {
          width: 8px;
          height: 8px;
          display: inline-block;
          background: url("../src/cmmon/image/product/delete.png") no-repeat;
          background-size: contain;
          -webkit-tap-highlight-color: transparent;
        }
      }
    }
  }
}

.eleBox-w {
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  .van-radio {
    font-size: 12px;
    margin: 10px 0 0 10px;
  }

  p {
    padding-left: 12px;
    border-bottom: 1px solid #f5f5f5;
    font: bold 14px/3 PingFangSC-Medium;
  }
}

.btnBox-w {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100%;

  span {
    display: inline-block;
    flex: 1;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #999;
    font-size: 16px;
    background-color: #fff;
    border-top: 1px solid #ececec;

    &:last-child {
      background-color: #4380F1;
      color: #fff;
      border-top: 1px solid #4380F1;
    }
  }
}

.fujian-w .imgUl-w {
  display: flex;
  padding: 0.2rem 0;
  flex-wrap: wrap;
  min-height: 2rem;

  li {
    width: 32%;
    height: 100%;
    margin: 0.2rem 0.1rem 0.1rem 0;
    position: relative;
    display: flex;
    justify-content: space-around;

    &:nth-child(3n) {
      margin-right: 0;
    }

    img {
      width: 80px;
      height: 80px;
    }

    .zoomimg {
      width: 60px;
      height: 60px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    i {
      position: absolute;
      font-size: 14px;
      top: -10px;
      right: 0.14rem;
      border-radius: 50%;
      display: inline-block;
      width: 16px;
      height: 16px;
      background: #b4b3b3;
      color: #fff;
      text-align: center;
      line-height: 16px;
    }
  }
}

.titleHeight-w {
  line-height: 1.5;
  position: relative;
  top: 8px;
}

.textarea-w {
  width: 100%;
  min-height: 1.8rem;
  font-size: .28rem;
  -webkit-tap-highlight-color: transparent;
  margin-top: 12px;
}

.prompt-w {
  padding: 10px 20px;
  color: #999;
  font-size: 13px;
  margin-top: 10px;
}

.redstart-w {
  display: inline-block;
  margin-right: .1rem;
  color: red;
}

.list-w {
  padding: 0 20px 30px 40px;
  margin-bottom: 20px;
}

.aduitIcon-w {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url('../src/cmmon/image/product/ed.png') no-repeat;
  background-size: contain;
}

.aduitInput-w {
  border: 1px solid #f5f5f5;
  padding-left: .1rem;
  width: 60%;
  height: 10px;
  font-size: .28rem;
}

.okAduit-w {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../src/cmmon/image/check.png') no-repeat;
  background-size: contain;
  margin-left: .8rem;
}

.celAduit-w {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../src/cmmon/image/detele.png') no-repeat;
  background-size: contain;
  margin-right: .12rem;
}

.aduit-w {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  margin-top: 5px;

  input {
    height: 30px;
  }
}

.pointimgbox-w {
  display: flex;
  flex-wrap: wrap;

  .imgbox-w {
    width: 31%;
    height: 100px;
  }
}

.imgbox-w {
  width: 31%;
  height: 100px;
  margin: 0 .1rem .1rem 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;

  &:nth-child(3n) {
    margin-right: 0;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.mint-toast {
  z-index: 2500 !important;
}

.tome {
  width: 1.33333rem;
  height: 1.33333rem;
  background-color: #b2b2b2;
  border-radius: 0.66667rem;
  opacity: 0.8;
  position: fixed;
  right: 0.53333rem;
  bottom: 2.52rem;
  box-shadow: 0.05333rem 0.05333rem 0.53333rem #bfbfbf;

  img {
    // float: right;
    margin-right: 10px;
    width: 100%;
  }
}

.el-table--scrollable-x .el-table__body-wrapper {
  &::-webkit-scrollbar-track-piece {
    background: #d3dce6;
  }

  &::-webkit-scrollbar {
    width: 2px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #464c55;
    border-radius: 20px;
  }
}
</style>
