import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    avatar: '',
    orderCount: '',
    shopCartCount: '0',
    isShow: '',
    LOADING: false,
    showcoupon: false,
    agentsMsg: {},
    switchWxCode: "",
    Scroll: 0,
    // 下单页搜索关键字
    newproductKey:'',
    // 我的奖品多选
    prizeArr:[],
    // 支付后弹窗提示内容
    Tips:'',
    // 晒单工具存储对象
    activedetialList:{}
  },
  mutations: {
    updateAgentsMsg(state, newVal) {
      state.agentsMsg = newVal
    },
    //个人图像
    getAvatar(state, newVal) {
      state.avatar = newVal
    },
    // 订单数量
    getOrderCount(state, newVal) {
      state.orderCount = newVal
    },
    computeOrderCount(state, count) {
      state.orderCount = state.orderCount - count;
    },
    // 购物车数量
    getShopCartCount(state, newVal) {
      state.shopCartCount = newVal
    },
    setshowcoupon(state, newVal) {
      state.showcoupon = newVal
    },
    sureShopCartCount(state, count) {
      state.shopCartCount += count;
    },
    // 购物车提交订单成功
    submitShopCart(state, newCount) {
      state.shopCartCount = newCount
    },
    // 删除购物车
    deleteShopCart(state, newCount) {
      state.shopCartCount = newCount
    },
    // 代理商状态
    agentIsShow(state, newState) {
      state.isShow = newState
    },
    showLoading(state) {
      state.LOADING = true
    },
    hideLoading(state) {
      state.LOADING = false
    },
    switchWxCode(state, val) {
      state.switchWxCode = val
    },
    themeScroll(state, val) {
      state.Scroll = val
    },
    setnewproductKet(state, val){
      state.newproductKey = val
    },
    setPrizeId(state, val){
      state.prizeArr = val
    },
    setTips(state, val){
      state.Tips = val
    },
    setactivedetialList(state, val){
      state.activedetialList = val
    }
  },
})
